import { useEffect, useState } from "react";

import Image from "next/image";

import { Trash2 } from "lucide-react";

import { useI18n } from "@repo/libs/providers/locales/client";
import { ProductType } from "@repo/libs/types/products";
import { emit } from "@libs/integrations/gtm/events";
import { CartItem } from "@libs/types/cart";
import { EventType } from "@libs/types/integrations/gtm/event";
import { roundToTwo } from "@libs/utils";

import Loader from "@ui/loader";

import { useCart } from "@website-toolkit/components/Providers/CartProvider";

import MerchandiseSummary from "./MerchandiseSummary";
import ReservationSummary from "./ReservationSummary";
import SubscriptionSummary from "./SubscriptionSummary";

export default function CheckoutSummary() {
  const t = useI18n();
  const { itemsRemove, isCartLoading, cartItems } = useCart();

  const [removingItemId, setRemovingItemId] = useState<string | null>(null);

  const handleRemoveItem = (item: any) => {
    if (!item.id) return;
    setRemovingItemId(item.id);

    emit({
      event: EventType.RemoveFromCart,
      item: {
        id: item.id,
        name: item.product.name,
        quantity: item.quantity,
      },
    });
    itemsRemove([item.id]);
  };

  useEffect(() => {
    if (!isCartLoading && removingItemId) {
      setRemovingItemId(null);
    }
  }, [isCartLoading, removingItemId]);

  return (
    <ul className="flex w-full flex-col gap-y-4 lg:pb-4">
      {cartItems?.map((item, index) => {
        if (!item || !item.product) return null;
        const product = item.product;

        return (
          <li
            key={"image-" + index}
            className="border-theme-background-700 flex items-stretch justify-between gap-x-4 rounded-xl border p-2 shadow-sm sm:p-4"
          >
            <div className="flex w-full items-center gap-x-4">
              <Image
                src={
                  product.images![0] ||
                  "https://cdn.rekaz.io/assets/placeholder.webp"
                }
                alt="product thumbnail"
                width={80}
                height={80}
                className="aspect-square rounded-xl object-cover"
              />
              <div>
                <h4 className="text-base font-bold">{product.name}</h4>
                {item.product.typeString === ProductType.RESERVATION && (
                  <ReservationSummary cartItem={item as CartItem} />
                )}
                {item.product.typeString === ProductType.SUBSCRIPTION && (
                  <SubscriptionSummary
                    cartItem={item as any}
                    quantity={item.quantity}
                  />
                )}
                {item.product.typeString === ProductType.MERCHANDISE && (
                  <MerchandiseSummary
                    cartItem={item as any}
                    removingItemId={removingItemId}
                  />
                )}
              </div>
            </div>
            <div className="flex w-fit flex-col items-end justify-between pt-1 lg:pt-0">
              {Boolean(isCartLoading && removingItemId === item.id) ? (
                <Loader />
              ) : (
                <Trash2
                  className="cursor-pointer hover:text-red-500"
                  onClick={() => !isCartLoading && handleRemoveItem(item)}
                />
              )}
              <div className="flex gap-x-[2px]">
                {item.effectiveQuantity && item.effectiveQuantity > 1 && (
                  <>
                    <span>{item.effectiveQuantity}</span>
                    <span className="mx-1">x</span>
                  </>
                )}
                <span>
                  {roundToTwo(Number(item.amount) + Number(item.taxAmount))}
                </span>
                <span> {t("sar")}</span>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
