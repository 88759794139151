"use client";

import { Suspense, useEffect } from "react";

import Link from "next/link";

import { useI18n } from "@libs/providers/locales/client";
import { useTenantRouter } from "@libs/providers/tenant/client";
import { CheckoutStep } from "@libs/types/checkout";

import { cn } from "@repo/ui/lib/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ui/accordion";
import { Button } from "@ui/button";
import { EmptyCart } from "@ui/icons";
import Loader from "@ui/loader";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import { useCheckoutStore } from "@repo/website-toolkit/src/stores/cart/useCheckoutStore";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";

import Coupon from "./Coupon";
import CustomerForm from "./CustomerForm";
import { Otp } from "./Otp";
import Package from "./Package";
import AmountSummary from "./Payment/AmountSummary";
import PaymentMethods from "./PaymentMethods";
import CheckoutSummary from "./Summary/CheckoutSummary";

export default function CheckoutSheetContent() {
  const t = useI18n();

  const { step, nextStep, prevStep } = useCheckoutStore((state) => state);
  const { closeSheet, cartItems = [] } = useCart();

  const { disableMultiCart, hasPackages } = useAppStore((state) => state);

  const otpStep =
    step === CheckoutStep.SendOTP || step === CheckoutStep.VerifyOTP;
  const router = useTenantRouter();

  if (!cartItems?.length) {
    return (
      <div className="m-auto flex w-full flex-col items-center justify-center gap-y-4">
        <EmptyCart className="text-theme-primary w-20" />
        <div className="max-w-md px-4 text-center">
          <h2 className="text-2xl font-bold">{t("cart.emptyStateTitle")}</h2>
          <h4>{t("cart.emptyStateDescription")}</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="scrollbar-hidden flex h-full flex-col justify-between overflow-y-auto lg:max-w-none lg:flex-row lg:overflow-y-hidden">
      <div
        className={cn(
          "scrollbar-hidden flex w-full p-3 max-lg:justify-between lg:w-1/2 lg:justify-end lg:overflow-y-auto lg:p-6",
        )}
      >
        <div className="w-full max-w-screen-lg">
          <Suspense fallback={<Loader />}>
            {(step === CheckoutStep.Summary || otpStep) && (
              <CheckoutSummary key={"checkout-summary"} />
            )}
            {otpStep && <Otp key={"send-otp"} />}
            {step === CheckoutStep.CustomerInfo && (
              <CustomerForm key={"check-otp"} />
            )}
            {step === CheckoutStep.SelectPaymentMethods && (
              <PaymentMethods key={"payment-methods"} />
            )}
          </Suspense>
        </div>
      </div>

      <div
        className={cn(
          "w-full p-3 lg:w-1/2 lg:items-start lg:p-6",
          step !== CheckoutStep.Summary && "hidden lg:block",
          cartItems.length === 0 && "hidden",
        )}
      >
        <div className="w-full max-w-screen-lg">
          <div
            className={cn(
              "border-theme-background-700 sticky h-fit w-full rounded-xl shadow-sm lg:top-0 lg:border lg:p-6",
            )}
          >
            <hr className="border-theme-background-700 my-4 lg:hidden" />

            <p className="mb-4 text-2xl font-semibold">
              {t("cart.paymentDetails")}
            </p>

            {hasPackages && (
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger>{t("cart.packages.add")}</AccordionTrigger>
                  <AccordionContent>
                    <Package />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
            {
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger>{t("cart.coupon.add")}</AccordionTrigger>
                  <AccordionContent>
                    <Coupon />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            }

            <AmountSummary />

            <div
              className={cn(
                "mt-8 hidden w-full items-center justify-center gap-x-2",
                step !== CheckoutStep.Summary && !otpStep
                  ? "hidden"
                  : "lg:flex",
              )}
            >
              <div className="w-full">
                <Button onClick={nextStep} className="w-full">
                  {t("continue")}
                </Button>
              </div>

              <div className="w-full">
                {step === CheckoutStep.Summary ? (
                  <Link href={router(`/products`)} className="block w-full">
                    <Button
                      variant={"outline"}
                      onClick={closeSheet}
                      className="w-full"
                    >
                      {!disableMultiCart ? t("cart.addAnother") : t("back")}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant={"outline"}
                    onClick={prevStep}
                    className="w-full"
                  >
                    {t("back")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
