"use client";

import { format } from "date-fns";
import { ar } from "date-fns/locale";
import { UserRound } from "lucide-react";

import { useCurrentLocale } from "@repo/libs/providers/locales/client";
import { CartItem } from "@libs/types/cart";

import { Calendar } from "@ui/icons";

export default function SubscriptionSummary({
  cartItem,
  quantity,
}: {
  cartItem?: CartItem;
  quantity?: number;
}) {
  const locale = useCurrentLocale() === "ar" ? ar : undefined;

  // for ts to know that this is a subscription
  if (cartItem?.product?.typeString !== "subscription") return null;

  const { subscriptionStartDate } = cartItem as any;
  return (
    <div className="space-y-2 text-sm font-medium">
      <h3>{cartItem.price?.name}</h3>
      <div className="flex items-start justify-start">
        <Calendar className="text-theme-primary-500 relative z-20 mx-2 mt-[2px] h-4 w-4 flex-shrink-0" />
        <div className="relative z-10 max-w-full break-words">
          {format(new Date(subscriptionStartDate), "EEEE، d MMMM yyyy", {
            locale,
          })}
        </div>
      </div>
      <div className="flex items-start justify-start">
        <UserRound className="mx-2 h-4 w-4" />
        <p className="line-clamp-1" data-state="closed">
          {quantity}
        </p>
      </div>
    </div>
  );
}
