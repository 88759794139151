import { useEffect, useState } from "react";

import { useMutation } from "@tanstack/react-query";

import { validateCoupon } from "@libs/api/cart";
import { useI18n } from "@libs/providers/locales/client";
import { CartItem } from "@libs/types/cart";
import { CouponCart } from "@libs/types/coupon";
import { cn } from "@libs/utils";

import { Button } from "@ui/button";
import { Input } from "@ui/input";

import { useCart } from "@website-toolkit/components/Providers/CartProvider";

export default function Coupon() {
  const t = useI18n();
  const { cartItems, couponId, couponCode, couponCodeUpdate } = useCart();

  const [couponCodeInput, setCouponCodeInput] = useState<string>(
    couponCode || "",
  );
  const [couponApplied, setCouponApplied] = useState<boolean>(
    couponId ? true : false,
  );

  useEffect(() => {
    if (!couponId) {
      setCouponApplied(false);
      setCouponCodeInput("");
    } else {
      setCouponApplied(true);
    }
  }, [couponId, couponApplied]);

  const couponMutation = useMutation({
    mutationFn: async (couponCart: CouponCart) =>
      couponCart && validateCoupon(couponCart),
    onSuccess: (data) => {
      couponCodeUpdate(data.code);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const clickHandler = () => {
    if (couponApplied) {
      setCouponCodeInput("");
      setCouponApplied((state) => !state);
      couponCodeUpdate("");
      couponMutation.reset();
    } else {
      if (!couponCodeInput) return;
      couponMutation.mutate({
        couponCode: couponCodeInput,
        cartItems: cartItems as CartItem[],
      });
    }
  };

  return (
    <>
      <div className={cn("my-4 w-full")}>
        <label
          htmlFor="coupon-code"
          className="block text-sm font-medium opacity-75"
        >
          {t("coupon")}
        </label>
        <div className="my-2 flex w-full items-start gap-x-2">
          <div className="flex w-full flex-col">
            <Input
              type="text"
              disabled={couponMutation.isPending || couponApplied}
              value={couponCodeInput}
              onChange={(e) => setCouponCodeInput(e.target.value)}
            />
          </div>
          <Button onClick={clickHandler}>
            {couponApplied ? t("remove") : t("cart.coupon.apply")}
          </Button>
        </div>
      </div>
      <div className="my-2 flex w-full items-start gap-x-2">
        <div className="flex w-full flex-col">
          {couponMutation.isError && (
            <p className="text-start text-sm text-red-500">
              {/* @ts-ignore */}
              {couponMutation.error.error.message}
            </p>
          )}
          {Boolean(couponId) && (
            <p className="text-sm text-green-500">{t("cart.coupon.applied")}</p>
          )}
        </div>
      </div>
    </>
  );
}
