import { Button } from "@repo/ui/components/button";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/form";
import { Input } from "@repo/ui/components/input";
import Loader from "@repo/ui/components/loader";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/select";
import { maxInput } from "@repo/libs/utils";
import { useI18n } from "@repo/libs/providers/locales/client";
import { CreditCard, UserIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";

export default function CardInformationFields() {
  const t = useI18n();
  const { control, formState } = useFormContext();
  return (
    <>
      <h3 className="text-xl font-semibold tracking-tight">
        {t("checkout.cardInformation")}
      </h3>
      <FormField
        control={control}
        name="name"
        defaultValue=""
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t("checkout.nameOnCard")}</FormLabel>
            <FormControl>
              <div className="relative grid">
                <Input
                  id="name"
                  placeholder={t("checkout.nameOnCard")}
                  {...field}
                />
                <UserIcon className="absolute end-2.5 top-2.5 h-5 w-5 text-gray-500" />
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="grid">
        <FormField
          control={control}
          defaultValue=""
          name="number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("checkout.cardNumber")}</FormLabel>
              <FormControl>
                <div className="relative">
                  <Input
                    type="number"
                    onInput={(e) => maxInput(e, 16)}
                    placeholder={t("checkout.cardNumber")}
                    {...field}
                  />
                  <CreditCard className="absolute end-2.5 top-2.5 h-5 w-5 text-gray-500" />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="grid gap-2">
          <FormField
            control={control}
            defaultValue=""
            name="month"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("checkout.expiryMonth")}</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange}>
                    <SelectTrigger id="month">
                      <SelectValue placeholder={t("checkout.month")} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="01">
                        <span>[1] {t("checkout.month.january")}</span>
                      </SelectItem>
                      <SelectItem value="02">
                        <span>[2] {t("checkout.month.february")}</span>
                      </SelectItem>
                      <SelectItem value="03">
                        <span>[3] {t("checkout.month.march")}</span>
                      </SelectItem>
                      <SelectItem value="04">
                        <span>[4] {t("checkout.month.april")}</span>
                      </SelectItem>
                      <SelectItem value="05">
                        <span>[5] {t("checkout.month.may")}</span>
                      </SelectItem>
                      <SelectItem value="06">
                        <span>[6] {t("checkout.month.june")}</span>
                      </SelectItem>
                      <SelectItem value="07">
                        <span>[7] {t("checkout.month.july")}</span>
                      </SelectItem>
                      <SelectItem value="08">
                        <span>[8] {t("checkout.month.august")}</span>
                      </SelectItem>
                      <SelectItem value="09">
                        <span>[9] {t("checkout.month.september")}</span>
                      </SelectItem>
                      <SelectItem value="10">
                        <span>[10] {t("checkout.month.october")}</span>
                      </SelectItem>
                      <SelectItem value="11">
                        <span>[11] {t("checkout.month.november")}</span>
                      </SelectItem>
                      <SelectItem value="12">
                        <span>[12] {t("checkout.month.december")}</span>
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid gap-2">
          <FormField
            control={control}
            defaultValue=""
            name="year"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("checkout.expiryYear")}</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange}>
                    <SelectTrigger id="year">
                      <SelectValue placeholder={t("checkout.year")} />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from({ length: 10 }, (_, i) => (
                        <SelectItem
                          key={i}
                          value={`${
                            +new Date().getFullYear().toString().slice(2) + i
                          }`}
                        >
                          {new Date().getFullYear() + i}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid gap-2">
          <FormField
            control={control}
            defaultValue=""
            name="cvc"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("checkout.cvc")}</FormLabel>
                <FormControl>
                  <Input type="number" placeholder="xxx" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </>
  );
}
