"use client";

import * as React from "react";
import { useEffect, useState } from "react";

import { useI18n } from "@libs/providers/locales/client";
import { CheckoutStep } from "@libs/types/checkout";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@repo/ui/components/sheet";
import { Modal, ModalDescription, ModalHeader, ModalTitle } from "@ui/modal";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import { useMediaQuery } from "@repo/website-toolkit/src/hooks/useMediaQuery";
import { useCheckoutStore } from "@website-toolkit/stores/cart/useCheckoutStore";
import { useCustomerStore } from "@website-toolkit/stores/useCustomerStore";

import SendOTPForm from "./SendOTPForm";
import VerifyOtp from "./VerifyOtp";

export function Otp() {
  const t = useI18n();

  const [open, setOpen] = useState(false);

  const isVerified = useCustomerStore.getState().otpVerified;

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { step, stack, reset } = useCheckoutStore((state) => state);
  const { hasPackages } = useAppStore((state) => state);

  useEffect(() => {
    if (isVerified && step !== CheckoutStep.VerifyOTP) setOpen(false);

    if (step === CheckoutStep.SendOTP || step === CheckoutStep.VerifyOTP)
      setOpen(true);
  }, [isVerified, step]);

  const onOpenChange = (open: boolean) => {
    if (!open && !isVerified) {
      reset();
    }
    setOpen(open);
  };

  if (isDesktop) {
    return (
      <Modal isOpen={open} onClose={() => onOpenChange(false)} isInsideCard>
        <ModalHeader className="space-y-1">
          <ModalTitle className="text-center">
            {step === CheckoutStep.SendOTP && t("checkout.otp")}
            {step === CheckoutStep.VerifyOTP && t("checkout.otpCode")}
          </ModalTitle>
          <ModalDescription>
            {step === CheckoutStep.VerifyOTP &&
              t("checkout.otpCode.description")}
          </ModalDescription>
        </ModalHeader>
        {step === CheckoutStep.SendOTP && <SendOTPForm key={"send-otp"} />}
        {step === CheckoutStep.VerifyOTP && (
          <VerifyOtp
            shouldBeAutoFocused={true}
            autoNext={() =>
              !hasPackages || stack[stack.length - 1] === CheckoutStep.SendOTP
            }
            key={"verify-otp"}
          />
        )}
      </Modal>
    );
  }

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent side={"bottom"} onOpenAutoFocus={(e) => e.preventDefault()}>
        <SheetHeader className="!text-center">
          <SheetTitle>
            {step === CheckoutStep.SendOTP && t("checkout.otp")}
            {step === CheckoutStep.VerifyOTP && t("checkout.otpCode")}
          </SheetTitle>
          <SheetDescription>
            {step === CheckoutStep.VerifyOTP &&
              t("checkout.otpCode.description")}
          </SheetDescription>
        </SheetHeader>

        {step === CheckoutStep.SendOTP && <SendOTPForm key={"send-otp"} />}
        {step === CheckoutStep.VerifyOTP && (
          <VerifyOtp
            shouldBeAutoFocused={true}
            autoNext={() =>
              !hasPackages || stack[stack.length - 1] === CheckoutStep.SendOTP
            }
            key={"verify-otp"}
          />
        )}
      </SheetContent>
    </Sheet>
  );
}
