"use client";
import { createToken, createPaymentSession } from "@libs/api/payment";
import { ApplicationError } from "@libs/errors/errors";
import CardInformationFields from "../CardInformationFields";
import { PaymentFormSubmitter } from "./PaymentFormSubmitter";

export class MoyasarPaymentFormSubmitter implements PaymentFormSubmitter {
  constructor(
    private publicKey: string,
    private cartId: string,
  ) {}
  async Init() {}

  async Submit(data: any, callbackUrl: string) {
    if (!this.publicKey) {
      throw new ApplicationError("Moyasar public token key is not set");
    }

    const tokenBody = {
      name: data.name,
      number: data.number,
      month: data.month,
      year: data.year,
      cvc: data.cvc,
      callback_url: callbackUrl,
      save_only: true,
    };

    const tokenResponse = await createToken(tokenBody, this.publicKey);

    const paymentSessionRequest = {
      callbackUrl: callbackUrl,
      token: tokenResponse.id,
      tokenType: "token",
      description: "Rekaz",
      reference: this.cartId!,
    };

    let session = await createPaymentSession(paymentSessionRequest);
    return session.redirectUrl;
  }

  Element() {
    return <CardInformationFields />;
  }
}
