import { EventType } from "@libs/types/integrations/meta/event";

export const emit = (type: EventType, options = {}) => {
  if ((window as any)?.fbq)
    (window as any)?.fbq("track", type.toString(), options);
};

export const emitCustomEvent = (name: string, options = {}) => {
  if ((window as any)?.fbq)
    (window as any)?.fbq("track", name, options);
};