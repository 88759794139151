"use client";

import React, { useEffect } from "react";
import { GoogleTagManager } from '@next/third-parties/google'
import { EventType } from "@libs/types/integrations/gtm/event";
import { Tenant } from "@libs/types/app";
import { emit } from "@libs/integrations/gtm/events";
import { usePathname } from "next/navigation";

type Props = {
  currentTenant?: Tenant;
  containerId: string;
}

const GoogleTagManger = ({ currentTenant, containerId }: Props) => {
  const pathname = usePathname();
  let initDataLayer: { [key: string]: any; } = {};

  if (typeof window !== "undefined") {
    initDataLayer = {
      event: EventType.Init,
      tenant: {
        id: currentTenant?.id,
        name: currentTenant?.name
      },
      page: {
        url: (window as any)?.location?.href
      }
    };
  }

  useEffect(() => {
    if (typeof window === "undefined") return;

    emit({
      event: EventType.PageView,
      page: {
        url: (window as any)?.location?.href
      }
    })
  }, [pathname]);

  return (
    <div>
      <GoogleTagManager dataLayer={initDataLayer} gtmId={containerId}></GoogleTagManager>
    </div>
  );
};

export default GoogleTagManger;
