import * as React from "react";

import { isRedirectError } from "next/dist/client/components/redirect";

import { z } from "zod";

import type { UploadedFile } from "@repo/libs/types/file-upload";

interface UseUploadFileOptions {
  uploadFn: (formData: FormData) => Promise<any>;
  onUploadBegin?: (files: File[]) => void | Promise<void>;
  onUploadProgress?: (progress: { file: File; progress: number }) => void;
  onError?: (error: unknown) => void;
  defaultUploadedFiles?: UploadedFile[];
}

export function useUploadFile({
  uploadFn,
  defaultUploadedFiles = [],
  onUploadBegin,
  onUploadProgress,
  onError,
}: UseUploadFileOptions) {
  const [uploadedFiles, setUploadedFiles] =
    React.useState<UploadedFile[]>(defaultUploadedFiles);
  const [progresses, setProgresses] = React.useState<Record<string, number>>(
    {},
  );
  const [isUploading, setIsUploading] = React.useState(false);

  async function onUpload(files: File[]) {
    setIsUploading(true);

    try {
      if (onUploadBegin) {
        await onUploadBegin(files);
      }

      const formData = new FormData();
      const totalSize = files.reduce((acc, file) => acc + file.size, 0);
      files.forEach((file) => {
        formData.append("files", file);
      });

      const stream = new ReadableStream({
        start(controller) {
          controller.enqueue(formData);
          controller.close();
        },
      });

      let uploadedSize = 0;
      const transformStream = new TransformStream({
        transform(chunk, controller) {
          uploadedSize += chunk.length;
          const progress = Math.round((uploadedSize * 100) / totalSize);

          files.forEach((file) => {
            setProgresses((prev) => ({
              ...prev,
              [file.name]: progress,
            }));

            if (onUploadProgress) {
              onUploadProgress({ file, progress });
            }
          });

          controller.enqueue(chunk);
        },
      });

      const response = await uploadFn(formData);

      // const result: UploadedFile[] = await response.json();
      // setUploadedFiles((prev) => [...prev, ...result]);
    } catch (err) {
      onError?.(err);
      // toast.error(getErrorMessage(err));
    } finally {
      setProgresses({});
      setIsUploading(false);
    }
  }

  const removeFile = (fileId: string) => {
    setUploadedFiles((prev) => prev.filter((file: any) => file.id !== fileId));
  };

  return {
    onUpload,
    uploadedFiles,
    progresses,
    isUploading,
    removeFile,
  };
}
export function getErrorMessage(err: unknown) {
  const unknownError = "Something went wrong, please try again later.";

  if (err instanceof z.ZodError) {
    const errors = err.issues.map((issue) => {
      return issue.message;
    });
    return errors.join("\n");
  } else if (err instanceof Error) {
    return err.message;
  } else if (isRedirectError(err)) {
    throw err;
  } else {
    return unknownError;
  }
}
