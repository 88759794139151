import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";

import { getCustomPage } from "@repo/libs/api";
import { useI18n } from "@repo/libs/providers/locales/client";

import { Button } from "@repo/ui/components/button";
import { Checkbox } from "@repo/ui/components/checkbox";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/form";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@repo/ui/components/sheet";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";

export default function TermsAndConditions() {
  const t = useI18n();
  const { control } = useFormContext();
  const [openTosModal, setOpenTosModal] = useState(false);

  const { data: tos } = useQuery<{ content: string }>({
    queryKey: ["tos"],
    queryFn: async () => await getCustomPage(2),
  });

  const tosMessage =
    useAppStore((state) => state)?.customTosMessage ||
    t("checkout.termsAndConditions");

  return (
    <>
      <div className="grid gap-2">
        <FormField
          control={control}
          name="terms"
          render={({ field }) => (
            <>
              <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value ?? false}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="flex items-center space-y-1 leading-none">
                  <FormLabel>
                    <div className="flex items-center">
                      <p className="ms-2 text-sm font-medium">{tosMessage}</p>
                    </div>
                  </FormLabel>
                  <span
                    onClick={() => setOpenTosModal(true)}
                    className="ms-1.5 text-sm font-medium text-blue-600 underline"
                  >
                    {t("terms.title").toLowerCase()}.
                  </span>
                </div>
              </FormItem>
              <FormMessage />
            </>
          )}
        />
      </div>
      <Sheet open={openTosModal} onOpenChange={setOpenTosModal}>
        <SheetContent className="w-full px-0 sm:max-w-2xl md:px-6">
          <SheetHeader className="flex h-full flex-col items-center justify-between">
            <SheetTitle>{t("terms.title")}</SheetTitle>
            <SheetDescription>
              <div className="grid gap-4 whitespace-pre-wrap md:gap-8">
                <div
                  className="scrollbar-hidden h-[80vh] overflow-y-scroll whitespace-pre-wrap p-3 text-start"
                  dangerouslySetInnerHTML={{
                    __html: tos?.content || t("terms.noTerms"),
                  }}
                />
              </div>
            </SheetDescription>
            <SheetFooter className="w-full">
              <Button
                className="mx-auto w-[80%]"
                onClick={() => setOpenTosModal(false)}
              >
                {t("terms.close")}
              </Button>
            </SheetFooter>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  );
}
