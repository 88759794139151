import { useI18n } from "@libs/providers/locales/client";
import { PaymentMethod } from "@libs/types/app";
import { CartItem } from "@libs/types/cart";
import { ProductType } from "@libs/types/products";
import { roundToTwo } from "@libs/utils";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ui/accordion";

import { useCart } from "@website-toolkit/components/Providers/CartProvider";

import MerchandiseSummary from "./MerchandiseSummary";
import ReservationSummary from "./ReservationSummary";
import SubscriptionSummary from "./SubscriptionSummary";

type MobileSummaryProps = {
  enabledPaymentMethods?: PaymentMethod[];
};
export default function MobileSummary({
  enabledPaymentMethods,
}: MobileSummaryProps) {
  const t = useI18n();

  const {
    cartItems = [],
    totalAmount = 0,
    depositAmount = 0,
    discountAmount = 0,
    amountToBePaid = 0,
  } = useCart();

  if (enabledPaymentMethods && enabledPaymentMethods.length > 1) return null;
  if (!enabledPaymentMethods?.includes(PaymentMethod.CASH)) return null;

  if (!cartItems || cartItems.length === 0) return null;

  const item = cartItems[0];
  return (
    <div className="scrollbar-hidden max-h-[50vh] overflow-y-scroll lg:hidden">
      <h2 className="mb-4 text-lg font-semibold">
        {t("checkout.orderInformation")}
      </h2>

      <div className="flex flex-col gap-y-4">
        {cartItems?.length > 1 ? (
          cartItems?.map((item, index) => {
            if (!item || !item.product) return null;
            const product = item.product;

            return (
              <Accordion
                key={"image-" + index}
                type="single"
                collapsible
                className="w-full"
              >
                <AccordionItem value={String(item?.id)} className="border-0">
                  <AccordionTrigger
                    className="flex justify-between pb-1"
                    iconClassName="order-2 ms-1"
                  >
                    <h4 className="text-base font-bold">{product.name}</h4>
                    <div className="order-3 ms-auto">
                      <span>
                        {roundToTwo(
                          Number(item.amount) + Number(item.taxAmount),
                        )}
                      </span>
                      <span> {t("sar")}</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="pt-2">
                      {item.product.typeString === ProductType.RESERVATION && (
                        <ReservationSummary cartItem={item as CartItem} />
                      )}
                      {item.product.typeString === ProductType.SUBSCRIPTION && (
                        <SubscriptionSummary
                          cartItem={item as any}
                          quantity={item.quantity}
                        />
                      )}
                      {item.product.typeString === ProductType.MERCHANDISE && (
                        <MerchandiseSummary
                          cartItem={item as any}
                          allowQuantityChange={false}
                        />
                      )}
                    </div>
                    {item.effectiveQuantity && item.effectiveQuantity > 1 && (
                      <>
                        <span>{item.effectiveQuantity}</span>
                        <span className="mx-1">x</span>
                      </>
                    )}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            );
          })
        ) : (
          <li className="flex items-stretch justify-between gap-x-4 rounded-xl p-2 sm:p-4">
            <div className="flex w-full items-center gap-x-4">
              <div>
                <h4 className="text-base font-bold">{item?.product?.name}</h4>
                {item?.product?.typeString === ProductType.RESERVATION && (
                  <ReservationSummary cartItem={cartItems[0] as CartItem} />
                )}
                {item?.product?.typeString === ProductType.SUBSCRIPTION && (
                  <SubscriptionSummary
                    cartItem={item as any}
                    quantity={item.quantity}
                  />
                )}
                {item?.product?.typeString === ProductType.MERCHANDISE && (
                  <MerchandiseSummary
                    cartItem={item as any}
                    allowQuantityChange={false}
                  />
                )}
              </div>
            </div>
            <div className="flex w-fit flex-col items-end justify-between pt-1 lg:pt-0">
              <div className="flex gap-x-[2px]">
                {item?.effectiveQuantity && item?.effectiveQuantity > 1 && (
                  <>
                    <span>{item.effectiveQuantity}</span>
                    <span className="mx-1">x</span>
                  </>
                )}
                <span>
                  {roundToTwo(Number(item?.amount) + Number(item?.taxAmount))}
                </span>
                <span> {t("sar")}</span>
              </div>
            </div>
          </li>
        )}
      </div>

      <div className="mt-6 flex flex-col gap-y-1">
        {discountAmount > 0 && (
          <div className="flex items-center justify-between">
            <span className="text-lg text-green-700">
              {t("cart.discountAmount")}
            </span>
            <span className="text-green-700">
              {discountAmount} {t("sar")}
            </span>
          </div>
        )}

        {depositAmount > 0 && (
          <div className="flex items-center justify-between">
            <span className="text-lg text-green-700">
              {t("cart.depositAmount")}
            </span>
            <span className="text-green-700">
              {depositAmount} {t("sar")}
            </span>
          </div>
        )}
        <div className="flex items-center justify-between font-semibold">
          <span>{t("cart.totalAmount")}</span>
          <span>
            {totalAmount} {t("sar")}
          </span>
        </div>
        <hr className="border-theme-background-700 my-2" />
        <div className="flex items-center justify-between font-semibold">
          <span>{t("cart.amountToBePaid")}</span>
          <span>
            {amountToBePaid} {t("sar")}
          </span>
        </div>
      </div>
    </div>
  );
}
