"use client";

import * as React from "react";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";

import { cn } from "../lib/utils";

const AccordionThemeContext = React.createContext<"customer" | "rekaz">(
  "customer",
);

const themeStyles = {
  customer: "border-theme-background-700 bg-theme-background text-theme-text",
  rekaz: "border-neutral-200 bg-white text-neutral-950",
};

const borderThemeStyles = {
  customer: "border-theme-background-700",
  rekaz: "border-neutral-200",
};

const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root> & {
    theme?: "customer" | "rekaz";
  }
>(({ className, theme = "customer", ...props }, ref) => (
  <AccordionThemeContext.Provider value={theme}>
    <AccordionPrimitive.Root
      ref={ref}
      className={cn(themeStyles[theme], className)}
      {...props}
    />
  </AccordionThemeContext.Provider>
));
Accordion.displayName = "Accordion";

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => {
  const theme = React.useContext(AccordionThemeContext);
  return (
    <AccordionPrimitive.Item
      ref={ref}
      className={cn("border-b", borderThemeStyles[theme], className)}
      {...props}
    />
  );
});
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    iconClassName?: string;
  }
>(({ className, iconClassName, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
        className,
      )}
      {...props}
    >
      {children}
      <ChevronDown
        className={cn(
          "h-4 w-4 shrink-0 transition-transform duration-200",
          iconClassName,
        )}
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={
      "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down text-sm transition-all data-[state=closed]:overflow-hidden"
    }
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
