"use client";

import { useEffect } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { I18nProviderClient } from "@repo/libs/providers/locales/client";
import { useTenantStore } from "@repo/libs/stores/useTenantStore";
import { ApplicationConfiguration } from "@repo/libs/types/app";

import { AppStoreProvider } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import { CartProvider } from "@website-toolkit/components/Providers/CartProvider";
import { useCustomerStore } from "@website-toolkit/stores/useCustomerStore";

const env = process.env.NODE_ENV;

if (typeof window !== "undefined" && env !== "development") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (typeof window === "undefined") {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}
export default function Providers({
  children,
  locale,
  config,
  tenant,
}: {
  children: React.ReactNode;
  locale: string;
  config: ApplicationConfiguration;
  tenant: string;
}) {
  const queryClient = getQueryClient();

  useEffect(() => {
    useTenantStore.setState({ tenant });
    useCustomerStore.setState({
      otpEnabled:
        config?.settings[
          "Platform.Checkout.SendOtpToCustomerBeforeCheckout"
        ] === "True",
    });
  }, []);

  return (
    <I18nProviderClient locale={locale}>
      <AppStoreProvider config={config}>
        <PostHogProvider client={posthog}>
          <QueryClientProvider client={queryClient}>
            <CartProvider adminMode={false}>{children}</CartProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PostHogProvider>
      </AppStoreProvider>
    </I18nProviderClient>
  );
}
