import {
  CreatePaymentSessionError,
  CreateTamaraSessionError,
  CreateTokenError,
} from "@libs/errors/paymentErrors";
import { request } from "@libs/request";
import {
  CreatePaymentSessionRequest,
  CreateTamaraSessionRequest,
  CreditCardFormData,
  MoyasarPaymentTokenResponse,
  MyFatoorahSession,
  PaymentOptions,
  PaymentOptionsRequest,
  TamaraSession,
} from "@libs/types/payment";
import { PaymentSession } from "@libs/types/payments";
import { BankAccount } from "@libs/types/payments/bankTransfer";

export async function paymentCallback(id: string) {
  const response = await request(
    "POST",
    `api/app/payment/${id}/callback-payment`,
  );
  return response;
}
export async function createPaymentSession(
  payload: CreatePaymentSessionRequest,
) {
  try {
    const response = await request("POST", "payment/moyasar/session", payload);
    return response.data as PaymentSession;
  } catch (error) {
    throw new CreatePaymentSessionError(error);
  }
}

export async function createToken(
  cardDetails: CreditCardFormData,
  token: string,
): Promise<MoyasarPaymentTokenResponse> {
  try {
    const response = await request(
      "POST",
      "https://api.moyasar.com/v1/tokens",
      cardDetails,
      {
        Authorization: "Basic " + btoa(token + ":"),
      },
    );
    return response.data as MoyasarPaymentTokenResponse;
  } catch (error) {
    throw new CreateTokenError(error);
  }
}

export async function createTamaraSession(payload: CreateTamaraSessionRequest) {
  try {
    const response = await request("POST", "api/app/tamara/cart-session", payload);
    return response.data as TamaraSession;
  } catch (error) {
    throw new CreateTamaraSessionError(error);
  }
}

export async function tamaraPaymentOptions(input: PaymentOptionsRequest) {
  const response = await request(
    "POST",
    "api/app/tamara/payment-options",
    input,
  );
  return response.data as PaymentOptions;
}

export async function createMyFatoorahSession(customerId: string) {
  const response = await request(
    "POST",
    `/api/app/payment/initiate-session/${customerId}`,
  );
  return response.data as MyFatoorahSession;
}

export async function myFatoorahExecutePayment(
  sessionId: string,
  cartId: string,
  callbackUrl: string,
  errorCallbackUrl: string,
) {
  const response = await request(
    "POST",
    `/api/app/payment/execute-cart-payment?sessionId=${sessionId}&cartId=${cartId}&callbackUrl=${callbackUrl}&errorCallbackUrl=${errorCallbackUrl}`,
  );
  return response.data;
}

export async function getBankAccounts() {
  const response = await request("GET", `api/app/bank-account/bank-account`);
  return response.data as BankAccount[];
}
