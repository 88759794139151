import { useEffect, useState } from "react";

import { createOtp } from "@libs/api";
import { useI18n } from "@libs/providers/locales/client";
import { CheckoutStep } from "@libs/types/checkout";
import { OtpPurpose } from "@libs/types/otp";
import { cn } from "@libs/utils";

import { Button } from "@ui/button";
import { Input } from "@ui/input";
import Loader from "@ui/loader";

import { useCart } from "@website-toolkit/components/Providers/CartProvider";
import { useCheckoutStore } from "@website-toolkit/stores/cart/useCheckoutStore";
import { useCustomerStore } from "@website-toolkit/stores/useCustomerStore";

export default function Package() {
    const t = useI18n();
    const { hasPackageDiscounts, customer, isCartLoading } = useCart();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [mobileNumberInput, setMobileNumberInput] = useState<string>(
        customer?.mobileNumber || "",
    );
    const otpToken = useCustomerStore((state) => state.otpToken);
    const otpVerified = useCustomerStore((state) => state.otpVerified);

    const { setStep } = useCheckoutStore.getState();

    const clickHandler = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        if (otpToken && otpVerified) {
            useCustomerStore.getState().reset();
            setIsSubmitting(false);
            return;
        }

        useCustomerStore.getState().reset();

        let otp = await createOtp({
            mobileNumber: mobileNumberInput,
            purpose: OtpPurpose.ConfirmCheckout,
        });
        useCustomerStore.setState((state) => ({
            otpToken: otp?.token,
            mobileNumber: mobileNumberInput,
        }));

        setStep(CheckoutStep.VerifyOTP);
        setIsSubmitting(false);
    };

    return (
        <>
            <div className={cn("my-4 w-full")}>
                <label
                    htmlFor="coupon-code"
                    className="block text-sm font-medium text-gray-700"
                >
                    {t("cart.mobile")}
                </label>
                <div className="my-2 flex w-full items-start gap-x-2">
                    <div className="flex w-full flex-col">
                        <Input
                            type="text"
                            placeholder={t("cart.packages.mobile")}
                            disabled={isSubmitting || Boolean(otpVerified)}
                            value={
                                useCustomerStore.getState().mobileNumber || mobileNumberInput
                            }
                            onChange={(e) => setMobileNumberInput(e.target.value)}
                        />
                    </div>
                    <Button onClick={clickHandler}>
                        {isSubmitting ? (
                            <Loader className="mx-2" />
                        ) : otpToken && otpVerified ? (
                            t("remove")
                        ) : (
                            t("cart.packages.apply")
                        )}
                    </Button>
                </div>
            </div>
            <div className="my-2 flex w-full items-start gap-x-2">
                <div className="flex w-full flex-col">
                    {!hasPackageDiscounts && otpVerified && !isCartLoading && (
                        <p className="text-start text-sm text-red-500">
                            {t("cart.packages.empty")}
                        </p>
                    )}
                    {hasPackageDiscounts && (
                        <p className="text-sm text-green-500">
                            {t("cart.packages.applied")}
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}
