import { ErrorType } from "@libs/types/error";
import { AppError } from "./errors";

export class MoyasarKeyNotSetError extends AppError {
  constructor(details?: any) {
    super(
      "Moyasar public token key is not set",
      "MOYASAR_KEY_NOT_SET",
      ErrorType.PAYMENT_ERROR,
      details,
    );
  }
}

export class CreateOrderError extends AppError {
  constructor(details?: any) {
    super(
      "Failed to create order",
      "CREATE_ORDER_ERROR",
      ErrorType.PAYMENT_ERROR,
      details,
    );
  }
}

export class CreateTokenError extends AppError {
  constructor(details?: any) {
    super(
      "Failed to create token",
      "CREATE_TOKEN_ERROR",
      ErrorType.PAYMENT_ERROR,
      details,
    );
  }
}

export class CreatePaymentSessionError extends AppError {
  constructor(details?: any) {
    super(
      "Failed to create payment session",
      "CREATE_PAYMENT_SESSION_ERROR",
      ErrorType.PAYMENT_ERROR,
      details,
    );
  }
}

export class ApplePayMerchantSessionError extends AppError {
  constructor(details?: any) {
    super(
      "Failed to validate Apple Pay merchant session",
      "APPLE_PAY_MERCHANT_SESSION_ERROR",
      ErrorType.PAYMENT_ERROR,
      details,
    );
  }
}

export class ApplePayPaymentError extends AppError {
  constructor(details?: any) {
    super(
      "Failed to process Apple Pay payment",
      "APPLE_PAY_PAYMENT_ERROR",
      ErrorType.PAYMENT_ERROR,
      details,
    );
  }
}

export class CreateTamaraSessionError extends AppError {
  constructor(details?: any) {
    super(
      "Failed to create tamara payment session",
      "CREATE_TAMARA_SESSION_ERROR",
      ErrorType.PAYMENT_ERROR,
      details,
    );
  }
}
