import {
  myFatoorahExecutePayment,
  createMyFatoorahSession,
} from "@repo/libs/api/payment";
import { getOrigin } from "@repo/libs/utils";
import { useState, useEffect } from "react";
import { PaymentFormSubmitter } from "./PaymentFormSubmitter";
import Script from "next/script";
import Loader from "@repo/ui/components/loader";
import { captureException } from "@sentry/nextjs";
import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import { PaymentMethod } from "@libs/types/app";
import { IBM_Plex_Sans_Arabic } from "next/font/google";

const src = process.env.NEXT_PUBLIC_MYFATOORAH_SCRIPT;

export class MyFatoorahPaymentFormSubmitter implements PaymentFormSubmitter {
  constructor(
    private CustomerId: string,
    private cartId: string,
    private tenantRouter: any,
  ) {}
  Init = async () => {
    // const src =
    //   process.env.NODE_ENV === "development"
    //     ? "https://demo.myfatoorah.com/cardview/v2/session.js"
    //     : "TODO";
    // const script = document.createElement("script");
    // script.src = src;
    // script.async = true;
    // document.body.appendChild(script);
  };
  Submit = async (data: any, callbackUrl: string) => {
    try {
      //  @ts-ignore
      var response = await window.myFatoorah.submit();
      return (
        await myFatoorahExecutePayment(
          response.sessionId,
          this.cartId,
          callbackUrl,
          getOrigin() + this.tenantRouter("/checkout/payment-error"),
        )
      ).redirectUrl;
    } catch (error) {
      captureException(error);
      window.location.reload(); //To restart the session and myfatoorah script
    }
  };
  Element = ({}) => {
    //  @ts-ignore
    const [isLoaded, setIsLoaded] = useState(window.myFatoorah !== undefined);
    const { enabledPaymentMethods } = useAppStore((s) => s);
    useEffect(() => {
      //  @ts-ignore
      if (isLoaded && window.myFatoorahCartId === undefined) {
        this.initializePaymentSession(enabledPaymentMethods);
        return () => {
          //  @ts-ignore
          if (window.myFatoorahCartId === this.cartId)
            //  @ts-ignore
            window.myFatoorahCartId = undefined;
        };
      }
    }, [isLoaded]);

    return (
      <>
        <style>
          {
            ".payment-form-loader{display:none;}\
             .payment-form:empty + .payment-form-loader{display:block;}\
            "
          }
        </style>

        <div className="payment-form" id="payment-form"></div>

        <div className="payment-form-loader my-8">
          <div className="flex justify-center">
            <Loader />
          </div>
        </div>
        <Script
          strategy="lazyOnload"
          id="myFatoorahScript"
          src={src}
          onLoad={() => {
            setIsLoaded(true);
            // @ts-ignore
            window.onload();
          }}
        ></Script>
      </>
    );
  };

  private initializePaymentSession(
    enabledPaymentMethods: PaymentMethod[] | undefined,
  ) {
    if (!enabledPaymentMethods || enabledPaymentMethods.length == 0)
      throw new Error("No payment methods enabled");
    const language = "ar";
    const paymentMethodString = [
      ...(enabledPaymentMethods.includes(PaymentMethod.MADA) ? ["md"] : []),
      ...(enabledPaymentMethods.includes(PaymentMethod.VISA) ? ["v", "m"] : []),
    ].join(",");
    createMyFatoorahSession(this.CustomerId).then((session) => {
      //  @ts-ignore
      if (window.myFatoorahCartId !== undefined) return;
      //  @ts-ignore
      window.myFatoorahCartId = this.cartId;
      console.log("MyFatoorahPaymentFormSubmitter session", session);
      const config = {
        countryCode: session.countryCode,
        sessionId: session.sessionId,
        cardViewId: "payment-form",
        supportedNetworks: paymentMethodString,
        style: {
          direction: language === "ar" ? "rtl" : "ltr",
          cardHeight: 260,
          input: {
            color: "rgb(66, 67, 71)",
            fontSize: "14px",
            fontFamily: "tahoma",
            inputHeight: "40px",
            inputMargin: "12px",
            borderColor: "rgb(229, 229, 229)",
            borderWidth: "1px",
            borderRadius: "6px",
            boxShadow: "",
            placeHolder: {
              holderName:
                language === "ar" ? "الاسم على البطاقة" : "Name On Card:",
              cardNumber: language === "ar" ? "رقم البطاقة" : "Card Number",
              expiryDate: language === "ar" ? "شهر / سنة" : "MM / YY",
              securityCode: language === "ar" ? "الرمز الأمني (CVV)" : "CVV",
            },
          },
          label: {
            display: true,
            color: "rgb(66, 67, 71)",
            fontSize: "14px",
            fontFamily: "tahoma",
            fontWeight: "500",
            text: {
              holderName:
                language === "ar" ? "الاسم على البطاقة" : "Name On Card:",
              cardNumber: language === "ar" ? "رقم البطاقة" : "Card Number",
              expiryDate: language === "ar" ? "شهر / سنة" : "MM / YY",
              securityCode: language === "ar" ? "الرمز الأمني (CVV)" : "CVV",
            },
          },
          error: {
            borderColor: "red",
            // borderRadius: "8px",
            // boxShadow: "0px",
          },
        },
      };
      //   @ts-ignore
      myFatoorah.init(config);
    });
  }
}

const ibmPlex = IBM_Plex_Sans_Arabic({
  subsets: ["arabic", "latin"],
  weight: ["400", "700"],
});
