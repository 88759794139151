"use client";

import { UUID } from "crypto";
import { useEffect, useState } from "react";

import { useI18n } from "@repo/libs/providers/locales/client";
import { CartItem } from "@libs/types/cart";

import Loader from "@ui/loader";

import { useCart } from "@website-toolkit/components/Providers/CartProvider";

export default function MerchandiseSummary({
  cartItem,
  removingItemId,
  allowQuantityChange = true,
}: {
  cartItem?: CartItem;
  removingItemId?: string | null;
  allowQuantityChange?: boolean;
}) {
  const t = useI18n();
  const [updatingItemId, setUpdatingItemId] = useState<string | null>(null);
  const { itemsUpdate, isCartLoading } = useCart();

  useEffect(() => {
    if (!isCartLoading && updatingItemId) {
      setUpdatingItemId(null);
    }
  }, [isCartLoading, updatingItemId]);

  if (cartItem?.product?.typeString !== "merchandise") return null;

  const updateItem = (amount: number) => {
    setUpdatingItemId(cartItem.id);
    itemsUpdate([
      {
        ...cartItem,
        priceId: cartItem.price?.id as UUID,
        quantity: cartItem.quantity + amount,
      },
    ]);
  };

  const isItemLoading =
    updatingItemId === cartItem.id && isCartLoading && !removingItemId;

  return (
    <div className="space-y-2 text-sm font-medium">
      <h3>{cartItem.price?.name}</h3>
      <div className="flex items-start justify-start">
        {allowQuantityChange ? (
          <div className="border-theme-background-700 border-text-theme-primary-500 w-fit rounded-full border">
            <label htmlFor="Quantity" className="sr-only">
              {t("quantity")}
            </label>
            <div className="flex items-center">
              <button
                type="button"
                className="px-2 hover:opacity-75 disabled:opacity-50"
                onClick={() => updateItem(-1)}
                disabled={isItemLoading}
              >
                &minus;
              </button>
              <div className="flex h-6 w-6 items-center justify-center">
                {isItemLoading ? (
                  <Loader className="h-4 w-4" />
                ) : (
                  <p>{cartItem?.quantity}</p>
                )}
              </div>
              <button
                type="button"
                className="px-2 hover:opacity-75 disabled:opacity-50"
                onClick={() => updateItem(1)}
                disabled={isItemLoading}
              >
                &#43;
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-start justify-start gap-x-4">
            <span>{t("quantity")}:</span>
            <p className="line-clamp-1" data-state="closed">
              {cartItem?.quantity}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
