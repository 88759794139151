import { useI18n } from "@libs/providers/locales/client";

import { cn } from "@repo/ui/lib/utils";

import { useCart } from "@website-toolkit/components/Providers/CartProvider";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";

export default function AmountSummary({ className }: { className?: string }) {
  const t = useI18n();

  const { taxEnabled } = useAppStore((state) => state);

  const {
    totalAmount = 0,
    subTotal = 0,
    depositAmount = 0,
    taxAmount = 0,
    discountAmount = 0,
    amountToBePaid = 0,
  } = useCart();

  return (
    <div className={cn("mt-2 flex flex-col gap-y-1", className)}>
      <div className="flex items-center justify-between">
        <span className="text-lg opacity-85">{t("cart.price")}</span>
        <span className="opacity-75">
          {subTotal} {t("sar")}
        </span>
      </div>
      {discountAmount > 0 && (
        <div className="flex items-center justify-between">
          <span className="text-lg text-green-700">
            {t("cart.discountAmount")}
          </span>
          <span className="text-green-700">
            {discountAmount} {t("sar")}
          </span>
        </div>
      )}
      {taxEnabled && (
        <div className="flex items-center justify-between">
          <span className="text-lg opacity-85">{t("cart.taxAmount")}</span>
          <span className="opacity-75">
            {taxAmount} {t("sar")}
          </span>
        </div>
      )}
      {depositAmount > 0 && (
        <div className="flex items-center justify-between">
          <span className="text-lg text-green-700">
            {t("cart.depositAmount")}
          </span>
          <span className="text-green-700">
            {depositAmount} {t("sar")}
          </span>
        </div>
      )}
      <div className="flex items-center justify-between font-semibold">
        <span>{t("cart.totalAmount")}</span>
        <span>
          {totalAmount} {t("sar")}
        </span>
      </div>
      <hr className="border-theme-background-700 my-2" />
      <div className="flex items-center justify-between font-semibold">
        <span>{t("cart.amountToBePaid")}</span>
        <span>
          {amountToBePaid} {t("sar")}
        </span>
      </div>
    </div>
  );
}
