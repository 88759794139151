import { Cart } from "@libs/types/cart";

import { NotFoundError, OnboardingError } from "../errors/errors";
import { request } from "../request";
import { useTenantStore } from "../stores/useTenantStore";
import {
  CreateOtp,
  CreateOtpOutput,
  VerifyOtp,
  VerifyOtpOutput,
} from "../types/otp";
import { generateGUID } from "../utils";

export async function onboarding() {
  try {
    const response = await request("GET", "api/app/setting-manager/onboarding");
    return response.data;
  } catch (error: any) {
    if (error.response.status >= 400 && error.response.status < 500) {
      throw new NotFoundError(error);
    } else {
      throw new OnboardingError(error);
    }
  }
}

export async function sendMessage(message: string) {
  let sessionId = localStorage.getItem("sessionId");
  const tenant = useTenantStore.getState().tenant;
  if (!sessionId) {
    // generateGUID is a fallback for browsers that don't support window.crypto.randomUUID
    sessionId = window.crypto?.randomUUID() || generateGUID();
    localStorage.setItem("sessionId", sessionId);
    const response = await fetch("/api/messages", {
      method: "POST",
      body: JSON.stringify({ message, sessionId }),
      headers: {
        __tenant: tenant || "",
      },
    });
    return response;
  }
}

export async function createOtp({
  mobileNumber,
  purpose,
}: CreateOtp): Promise<CreateOtpOutput> {
  const response = await request("POST", "api/app/otp", {
    mobileNumber,
    purpose,
  });

  return response.data as CreateOtpOutput;
}

export async function verifyOtpForCart({
  cartId,
  token,
  code,
}: VerifyOtp): Promise<Cart> {
  const response = await request("POST", `api/app/cart/verify-otp/${cartId}`, {
    code,
    token,
  });

  return response.data as Cart;
}

export async function verifyCustomerAndGetStatus(
  mobileNumber: string,
  otpToken: string,
  otpCode: string,
) {
  const response = await request(
    "POST",
    "api/app/customer-status/verify-and-status",
    {
      mobileNumber,
      otpToken,
      otpCode,
    },
  );
  return response.data;
}

export async function getCustomPage(type: number) {
  try {
    const response = await request(
      "GET",
      `api/app/custom-page/by-type?type=${type}`,
    );
    return response.data;
  } catch (error) {
    return null;
    throw new NotFoundError((error as any).error.message);
  }
}

export async function getAllCustomPages() {
  try {
    const response = await request("GET", "api/app/custom-page/active");
    return response.data;
  } catch (error) {
    return null;
    throw new NotFoundError((error as any).error.message);
  }
}

export async function getDocument(documentId: string) {
  const response = await request(
    "GET",
    `api/app/document${documentId}/document`,
  );
  return response.data;
}
