import { useCallback, useEffect, useRef, useState } from "react";

import { useRouter } from "next/navigation";
import Script from "next/script";

import { captureException } from "@sentry/nextjs";

import {
  createMyFatoorahSession,
  myFatoorahExecutePayment,
  paymentCallback,
} from "@libs/api/payment";
import { useI18n } from "@libs/providers/locales/client";
import { useTenantRouter } from "@libs/providers/tenant/client";
import { getOrigin } from "@libs/utils";

import { Button } from "@ui/button";
import { Apple } from "@ui/icons";
import Loader from "@ui/loader";
import { useToast } from "@ui/use-toast";

import { useCartStore } from "@website-toolkit/stores/cart/useCartStore";

import { ApplePayProps } from "./ApplePay";

const src = process.env.NEXT_PUBLIC_MYFATOORAH_AP_SCRIPT;

export default function MyfatoorahApplePay(props: ApplePayProps) {
  const [isLoaded, setIsLoaded] = useState(!!window.myFatoorahAP);
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const { id: cartId } = useCartStore((s) => s);
  const router = useRouter();
  const tenantRouter = useTenantRouter();
  const { toast } = useToast();
  const t = useI18n();
  const [error, setError] = useState<string | undefined>(undefined);

  const callbackFunc = useCallback(
    async (sessionId: string) => {
      try {
        const paymentErrorRedirectUrl =
          getOrigin() + tenantRouter("/checkout/payment-error");
        const redirectUrl: string = (
          await myFatoorahExecutePayment(
            sessionId,
            cartId!,
            props.callbackUrl,
            paymentErrorRedirectUrl,
          )
        ).redirectUrl;

        try {
          await fetch(redirectUrl);
        } catch (error) {}

        if (!redirectUrl) throw new Error("No redirect url found");
        const url = new URL(redirectUrl);
        const paymentId = url.searchParams.get("paymentId");

        if (!paymentId) throw new Error("No payment id found");
        const { data } = await paymentCallback(paymentId);

        if (data.status === 2)
          router.push(tenantRouter(`/checkout/done/${cartId}`));
        else {
          setError(data.errorMessageCode);
        }
      } catch (error: any) {
        captureException(error);
        setError("");
      }
    },
    [cartId, props.callbackUrl],
  );

  const initializeMyFatoorahSession = useCallback(async () => {
    if (window.myFatoorahCartId !== undefined) return;
    try {
      setIsSessionLoading(true);
      const session = await createMyFatoorahSession(props.CustomerId);
      if (window.myFatoorahCartId !== undefined) return;
      window.myFatoorahCartId = cartId;
      const config = {
        countryCode: session.countryCode,
        sessionId: session.sessionId,
        currencyCode: "SAR",
        amount: props.amount,
        // cardViewId: "myfatoorah-apple-pay",
        language: "ar",
        callback: (e: any) => {
          callbackFunc(e.sessionId);
        },
        sessionCanceled: (e: any) => {
          console.log("sessionCanceled", e);
          setError("");
        },
        style: {
          frameHeight: "40",
          button: {
            height: "40px",
          },
        },
      };

      window.myFatoorahAP.init(config);
    } finally {
      setIsSessionLoading(false);
    }
  }, [callbackFunc, cartId, props.CustomerId, props.amount]);

  const reset = useCallback(() => {
    // setIsLoaded(false);
    setIsSessionLoading(false);
    window.myFatoorahCartId = undefined;
    // window.myFatoorahAP = undefined;
    // // @ts-ignore
    // window.onload();
    // ref.current!.innerHTML = "";
    initializeMyFatoorahSession();
  }, [initializeMyFatoorahSession]);

  useEffect(() => {
    if (error === undefined) return;
    setError(undefined);
    reset();
    // initializeMyFatoorahSession();
    toast({
      title: t("error.title"),
      description: error,
      duration: 6000,
      variant: "destructive",
    });
  }, [error, toast, t, initializeMyFatoorahSession, reset]);

  useEffect(() => {
    if (!isLoaded) return;
    initializeMyFatoorahSession();
    return () => {
      //  @ts-ignore
      if (window.myFatoorahCartId === cartId)
        window.myFatoorahCartId = undefined;
    };
  }, [cartId, initializeMyFatoorahSession, isLoaded]);

  const onApplePayButtonClicked = () => {
    if (isSessionLoading) return;
    setIsSessionLoading(true);
    window.myFatoorahAP.initPayment();
  };
  return (
    <>
      <Script
        src={src}
        strategy="lazyOnload"
        onLoad={() => {
          setIsLoaded(true);
          // @ts-ignore
          window.onload();
        }}
      />
      {isLoaded && (
        <>
          <Button
            className="w-full py-5"
            onClick={onApplePayButtonClicked}
            loading={isSessionLoading}
          >
            <Apple className="h-[1.15rem] w-auto text-white" />
          </Button>
        </>
      )}
      {!isLoaded && (
        <div className="payment-form-loader my-8">
          <div className="flex justify-center">
            <Loader />
          </div>
        </div>
      )}
    </>
  );
}
