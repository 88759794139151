"use client";

import * as React from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

import { cn } from "../lib/utils";

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  theme?: "customer" | "rekaz";
}

const themeStyles = {
  customer: {
    base: "border-theme-background-700 ring-offset-theme-background focus-visible:ring-theme-primary",
    checked:
      "data-[state=checked]:bg-theme-primary data-[state=checked]:text-theme-background",
  },
  rekaz: {
    base: "border-neutral-200 ring-offset-white focus-visible:ring-neutral-950",
    checked:
      "data-[state=checked]:bg-neutral-900 data-[state=checked]:text-neutral-50",
  },
};

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, theme = "customer", ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer h-4 w-4 shrink-0 rounded border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      theme === "customer"
        ? `${themeStyles.customer.base} ${themeStyles.customer.checked}`
        : `${themeStyles.rekaz.base} ${themeStyles.rekaz.checked}`,
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Check className="size-3" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
