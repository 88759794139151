"use client";

import { memo } from "react";

import { format, Locale } from "date-fns";
import { ar } from "date-fns/locale";
import { UserRound } from "lucide-react";

import { useCurrentLocale } from "@repo/libs/providers/locales/client";
import { compareDate, parseAndFormatTime } from "@repo/libs/utils";
import { CartItem } from "@libs/types/cart";
import { Operator } from "@libs/types/products";
import { ReservationProduct } from "@libs/types/products/reservation";

import { Calendar, Clock } from "@ui/icons";

export default memo(function ReservationSummary({
  cartItem,
}: {
  cartItem: CartItem;
}) {
  if (!("reservationDateFrom" in cartItem)) throw "";
  const reservation = cartItem.product as ReservationProduct;

  const locale = useCurrentLocale() === "ar" ? ar : undefined;

  const isFullDay = reservation.isFullDayBooking;
  const isVariableDuration = reservation.isVariableDuration;

  var { reservationDateFrom, reservationDateTo } = cartItem;

  if (isFullDay)
    reservationDateTo = new Date(
      new Date(reservationDateTo).valueOf() - 24 * 60 * 60 * 1000,
    ).toISOString();

  reservationDateFrom = reservationDateFrom.split("Z")[0];
  reservationDateTo = reservationDateTo.split("Z")[0];

  const formattedDateFrom = getDateString(reservationDateFrom, locale);
  const formattedDateTo = getDateString(reservationDateTo, locale);

  const formattedTimeFrom = getTimeString(reservationDateFrom, locale);
  const formattedTimeTo = getTimeString(reservationDateTo, locale);

  const isFromAndToOnSameDate = compareDate(
    new Date(reservationDateFrom),
    new Date(reservationDateTo),
    Operator.Equal,
  );

  return (
    <div className="space-y-2 font-medium">
      {(isFullDay || (isVariableDuration && isFromAndToOnSameDate)) && (
        <div className="flex items-start justify-start text-sm">
          <Calendar className="text-theme-primary-500 relative z-20 mr-2 mt-[2px] h-4 w-4 flex-shrink-0 rtl:ml-2" />
          <div className="relative z-10 max-w-full break-words">
            {formattedDateFrom}
            {isFromAndToOnSameDate ? "" : " - " + formattedDateTo}
          </div>
        </div>
      )}

      {!isFullDay && (
        <div className="flex items-start justify-start text-sm">
          <Clock className="text-theme-primary-500 relative z-20 mr-2 mt-[2px] h-4 w-4 flex-shrink-0 rtl:ml-2" />
          <div className="relative z-10 max-w-full break-words">
            {isFromAndToOnSameDate ? (
              formattedTimeFrom + " - " + formattedTimeTo
            ) : (
              <>
                {formattedDateFrom + " " + formattedTimeFrom}
                <br />
                {formattedDateTo + " " + formattedTimeTo}
              </>
            )}
          </div>
        </div>
      )}
      {cartItem.quantity && cartItem.quantity > 1 && (
        <div className="flex items-start justify-start text-sm">
          <UserRound className="text-theme-primary-500 mx-2 h-4 w-4" />
          <p className="line-clamp-1" data-state="closed">
            {cartItem.quantity}
          </p>
        </div>
      )}
    </div>
  );
});
function getDateString(reservationDateFrom: string, locale?: Locale) {
  return format(reservationDateFrom.split("Z")[0], "EEEE، d MMMM yyyy", {
    locale,
  });
}
function getTimeString(reservationDateFrom: string, locale?: Locale) {
  return parseAndFormatTime(reservationDateFrom.split("Z")[0], "12h", locale);
}
