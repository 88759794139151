import { NotFoundError } from "@libs/errors/errors";
import { request } from "@libs/request";
import { CustomField } from "@libs/types/customFields";

export async function getCustomerCustomFields(): Promise<CustomField[]> {
  try {
    const response = await request(
      "GET",
      `api/app/customer/checkout-custom-fields`,
    );
    return response.data as CustomField[];
  } catch (error) {
    throw new NotFoundError((error as any).error.message);
  }
}
