"use client";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";

import MoyasarApplePay from "./MoyasarApplePay";
import MyfatoorahApplePay from "./MyfatoorahApplePay";

export type ApplePayProps = {
  amount?: string;
  callbackUrl: string;
  validateTos: () => Promise<boolean>;
  CustomerId: string;
};
export default function ApplePay(props: ApplePayProps) {
  const { onlinePaymentProvider } = useAppStore((s) => s);

  return onlinePaymentProvider === "moyasar" ? (
    <MoyasarApplePay {...props} />
  ) : onlinePaymentProvider === "myfatoorah" ? (
    <MyfatoorahApplePay {...props} />
  ) : (
    <div>Apple Pay is not supported</div>
  );
}
