export type EventMessage = {
    event: EventType;
    gtm?: GtmData;
    [key: string]: any;
}

export type GtmData = {
    uniqueEventId: string;
}

export enum EventType {
    Init = "rekaz::init",
    PageView = "rekaz::pages.view",
    ReservationView = "rekaz::reservations.view",
    ReservationSelectOption = "rekaz::reservations.select",
    ReservationSelectProvider = "rekaz::reservations.provider.select",
    SubscriptionView = "rekaz::subscriptions.view",
    SubscriptionSelectOption = "rekaz::subscriptions.select",
    InitiateCheckout = "rekaz::checkout.init",
    AddToCart = "rekaz::cart.add",
    RemoveFromCart = "rekaz::cart.remove",
    AddPaymentInfo = "rekaz::cart.add_payment_info",
    Purchase = "rekaz::purchase",
}