"use client";

import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { createOtp } from "@repo/libs/api";
import { useI18n } from "@repo/libs/providers/locales/client";
import { CreateOtpOutput, OtpPurpose } from "@repo/libs/types/otp";
import { convertToEnglishNumbers } from "@libs/utils";

import { useToast } from "@repo/ui/components/use-toast";
import { Button } from "@ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@ui/form";
import { Input } from "@ui/input";

import { useCustomerStore } from "@repo/website-toolkit/src/stores/useCustomerStore";
import { useCheckoutStore } from "@website-toolkit/stores/cart/useCheckoutStore";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";

export default function CustomerForm() {
  const t = useI18n();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { otpEnabled } = useAppStore((state) => state);
  const { customer } = useCart();

  const formSchema = z.object({
    customerMobileNumber: z
      .string()
      .min(10, t("validation.numberTooShort"))
      .max(10, t("validation.numberTooLong"))
      .transform((val) => convertToEnglishNumbers(val))
      .pipe(z.string().regex(/^05\d{8}$/, t("validation.saudiMobileNumber"))),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      customerMobileNumber: "",
    },
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);

      useCustomerStore.getState().reset();

      let otp: CreateOtpOutput;
      if (otpEnabled) {
        otp = await createOtp({
          mobileNumber: data.customerMobileNumber,
          purpose: OtpPurpose.ConfirmCheckout,
        });
      }
      useCustomerStore.setState((state) => ({
        otpToken: otp?.token,
        mobileNumber: state.mobileNumber || data.customerMobileNumber,
      }));
    } catch (err) {
      console.error(err);
      const error = (err as any).error;
      toast({
        title: error?.message,
        description: error?.details,
        variant: "destructive",
      });
    }
    setIsSubmitting(false);
    useCheckoutStore.getState().nextStep();
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="grid gap-y-4 px-4 pb-4"
      >
        <FormField
          defaultValue={customer?.mobileNumber}
          name="customerMobileNumber"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="grid">
                  <Input
                    type="tel"
                    autoFocus
                    placeholder={t("checkout.customerMobileNumberPlaceholder")}
                    {...field}
                  />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />{" "}
        <Button className="w-full" type="submit" loading={isSubmitting}>
          {t("continue")}
        </Button>
      </form>
    </Form>
  );
}
