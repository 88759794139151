"use client";

import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useI18n } from "@repo/libs/providers/locales/client";
import { getCustomerCustomFields } from "@libs/api/customer";
import { getCustomFieldsZodSchema } from "@libs/customFields";
import { CustomField, CustomFieldInput } from "@libs/types/customFields";
import { convertToEnglishNumbers } from "@libs/utils";

import { useToast } from "@repo/ui/components/use-toast";
import { Button } from "@ui/button";
import { Form } from "@ui/form";
import Loader from "@ui/loader";

import { useCheckoutStore } from "@repo/website-toolkit/src/stores/cart/useCheckoutStore";
import { useCustomerStore } from "@repo/website-toolkit/src/stores/useCustomerStore";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import CustomerInformationFields from "./Payment/CustomerInformationFields";

export default function CustomerForm() {
  const t = useI18n();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { separateFirstAndLastName } = useAppStore((state) => state);

  const { isCartLoading } = useCart();
  const { prevStep } = useCheckoutStore((state) => state);

  const { data: customFields } = useQuery({
    queryKey: ["customerCustomFields"],
    queryFn: async () => (await getCustomerCustomFields()) as CustomField[],
  });

  const formSchema = z.object({
    customerMobileNumber: z
      .string()
      .min(10, t("validation.numberTooShort"))
      .max(10, t("validation.numberTooLong"))
      .transform((val) => convertToEnglishNumbers(val))
      .pipe(z.string().regex(/^05\d{8}$/, t("validation.saudiMobileNumber"))),
    customerName: !separateFirstAndLastName
      ? z.string().min(1, t("validation.required"))
      : z.string().optional(),
    customerFirstName: separateFirstAndLastName
      ? z.string().min(1, t("validation.required"))
      : z.string().optional(),
    customerLastName: separateFirstAndLastName
      ? z.string().min(1, t("validation.required"))
      : z.string().optional(),
    ...getCustomFieldsZodSchema(customFields, t("validation.required")),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const { customerIdentityUpdate } = useCart();

  async function onSubmit() {
    const data = form.getValues();

    const isValid = await form.trigger();
    if (!isValid) return;

    try {
      if (isSubmitting) return;
      setIsSubmitting(true);

      const customerName = data.customerName
        ? data.customerName
        : data.customerFirstName + " " + data.customerLastName;
      useCustomerStore.setState((state) => ({
        name: state.name || customerName,
        mobileNumber: state.mobileNumber || data.customerMobileNumber,
        customFieldValues: customFields?.reduce((acc, field) => {
          acc[field.name] = form.getValues<any>(field.name);
          return acc;
        }, {} as CustomFieldInput),
      }));

      customerIdentityUpdate({
        name: customerName,
        phone: data.customerMobileNumber,
        customFields: customFields?.reduce((acc, field) => {
          acc[field.name] = form.getValues<any>(field.name);
          return acc;
        }, {} as any),
      });
    } catch (err) {
      console.error(err);
      const error = (err as any).error;
      toast({
        title: error?.message,
        description: error?.details,
        variant: "destructive",
      });
    }
    setIsSubmitting(false);
  }

  return (
    <Form {...form}>
      <h3 className="pb-4 text-xl font-semibold tracking-tight">
        {t("checkout.customerInformation")}
      </h3>
      <form className="flex h-full flex-col gap-y-4 pb-20">
        <CustomerInformationFields />

        <div className="mt-auto hidden items-center gap-x-2 px-1 lg:mt-8 lg:flex">
          <Button
            disabled={isSubmitting || isCartLoading}
            className="w-full"
            type="button"
            onClick={onSubmit}
          >
            {Boolean(isSubmitting || isCartLoading) ? (
              <Loader className="mx-2" />
            ) : (
              t("continue")
            )}
          </Button>

          <Button
            type="button"
            variant={"outline"}
            onClick={prevStep}
            className="w-full"
          >
            {t("back")}
          </Button>
        </div>

        <div className="fixed bottom-0 left-0 right-0 flex w-full justify-center bg-white p-4 lg:hidden">
          <div className="mx-auto mt-auto flex w-full items-center gap-x-2">
            <div className="w-1/2">
              <Button
                disabled={isSubmitting || isCartLoading}
                className="w-full"
                type="button"
                onClick={onSubmit}
              >
                {Boolean(isSubmitting || isCartLoading) ? (
                  <Loader className="mx-2" />
                ) : (
                  t("continue")
                )}
              </Button>
            </div>

            <div className="w-1/2">
              <Button
                type="button"
                variant={"outline"}
                onClick={prevStep}
                className="w-full"
              >
                {t("back")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
