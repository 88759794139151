import {
  ChevronLeft,
  ChevronRight,
  CreditCard,
  ShoppingCart,
  User,
} from "lucide-react";

import { useCurrentLocale, useI18n } from "@libs/providers/locales/client";
import { CheckoutStep } from "@libs/types/checkout";
import { cn } from "@libs/utils";

import { useCheckoutStore } from "@website-toolkit/stores/cart/useCheckoutStore";

export default function ProgressBar({
  currentStep,
}: {
  currentStep: CheckoutStep;
  className?: string;
}) {
  const dir = useCurrentLocale() === "ar" ? "rtl" : "ltr";
  const t = useI18n();

  const steps = [
    {
      label: t("step.cart"),
      icon: ShoppingCart,
      internalSteps: [
        CheckoutStep.Summary,
        CheckoutStep.SendOTP,
        CheckoutStep.VerifyOTP,
      ],
    },
    {
      label: t("step.customerInfo"),
      icon: User,
      internalSteps: [CheckoutStep.CustomerInfo],
    },
    {
      label: t("step.payment"),
      icon: CreditCard,
      internalSteps: [CheckoutStep.SelectPaymentMethods, CheckoutStep.Payment],
    },
  ];
  const { setStep } = useCheckoutStore((state) => state);

  const setStepHandler = (step: CheckoutStep) => {
    if (step !== CheckoutStep.Summary) return;
    setStep(step);
  };

  var currentStepIndex = steps.findIndex((step) =>
    step.internalSteps.includes(currentStep),
  );

  return (
    <nav aria-label="Progress">
      <ol role="list" className="divide-theme-background-600 flex divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.label} className="relative flex items-center">
            {stepIdx < currentStepIndex ? (
              <div
                className="group flex w-full items-center"
                onClick={() => setStepHandler(step.internalSteps[0])}
              >
                <span className="flex items-center py-4 text-lg font-medium">
                  <step.icon className="me-2 hidden h-5 w-5" />
                  <span className="text-lg font-medium">{step.label}</span>
                </span>
              </div>
            ) : stepIdx === currentStepIndex ? (
              <div
                aria-current="step"
                className="flex items-center py-4 text-lg font-medium"
              >
                <step.icon className="me-2 hidden h-5 w-5" />
                <span className="border-theme-text border-b-2 text-lg font-medium">
                  {step.label}
                </span>
              </div>
            ) : (
              <div
                className="group flex w-full items-center"
                onClick={() => setStepHandler(step.internalSteps[0])}
              >
                <span className="flex items-center py-4 text-lg font-medium opacity-75 group-hover:opacity-100">
                  <step.icon className="me-2 hidden h-5 w-5" />
                  <span className="text-lg font-medium">{step.label}</span>
                </span>
              </div>
            )}
            {stepIdx !== steps.length - 1 && (
              <div
                aria-hidden="true"
                className={cn(
                  "mx-2",
                  stepIdx < currentStepIndex ? "text-theme-text" : "opacity-75",
                )}
              >
                {dir === "ltr" ? (
                  <ChevronRight className="mt-1 h-5 w-5" />
                ) : (
                  <ChevronLeft className="mt-1 h-5 w-5" />
                )}
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
