"use client";

import { useEffect } from "react";

import { usePathname, useSearchParams } from "next/navigation";

import { useCartStore } from "@website-toolkit/stores/cart/useCartStore";
import { useProductStore } from "@website-toolkit/stores/product/useProductStore";

export function NavigationEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (
      typeof document !== "undefined" &&
      typeof window.ResizeObserver === "undefined"
    ) {
      //@ts-ignore
      window.ResizeObserver = function () {
        return { observe() {}, unobserve() {} };
      };
    }
  }, [pathname, searchParams]);

  return null;
}
