"use client";

import { useEffect, useState } from "react";

import { useRouter } from "next/navigation";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import isCreditCard from "validator/es/lib/isCreditCard";
import { z } from "zod";

import { createTamaraSession, getBankAccounts } from "@repo/libs/api/payment";
import { ApplicationError } from "@repo/libs/errors/errors";
import { useI18n } from "@repo/libs/providers/locales/client";
import { useTenantRouter } from "@repo/libs/providers/tenant/client";
import { PaymentMethod } from "@repo/libs/types/app";
import { getOrigin } from "@repo/libs/utils";
import { setBankTransferInfo, validateCart } from "@libs/api/cart";
import { isValidErrorCode } from "@libs/errors/errorsEnum";
import { emit } from "@libs/integrations/gtm/events";
import { EventType } from "@libs/types/integrations/gtm/event";
import { BankAccount } from "@libs/types/payments/bankTransfer";

import { Form } from "@repo/ui/components/form";
import { useToast } from "@repo/ui/components/use-toast";
import { Button } from "@ui/button";
import { Card, CardContent, CardHeader } from "@ui/card";
import Loader from "@ui/loader";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import { useUploadFile } from "@repo/website-toolkit/src/hooks/useUploadFile";
import { useCustomerStore } from "@repo/website-toolkit/src/stores/useCustomerStore";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";
import { useCheckoutStore } from "@website-toolkit/stores/cart/useCheckoutStore";

import PaymentMethods from "../PaymentMethods";
import MobileSummary from "../Summary/MobileSummary";
import TermsAndConditions from "../TermsAndCondition";
import ApplePay from "./ApplePay";
import BankTransfer from "./BankTransfer";
import { MoyasarPaymentFormSubmitter } from "./PaymentFormSubmitters/MoyasarPaymentFormSubmitter";
import { MyFatoorahPaymentFormSubmitter } from "./PaymentFormSubmitters/MyFatoorahPaymentFormSubmitter";
import { PaymentFormSubmitter } from "./PaymentFormSubmitters/PaymentFormSubmitter";

type PaymentFormOptions = {
  paymentMethod?: PaymentMethod;
  enabledPaymentMethods?: PaymentMethod[];
  callbackUrl: string;
  codLabel?: string;
};

export default function PaymentForm({
  paymentMethod,
  enabledPaymentMethods,
  callbackUrl,
  codLabel,
}: PaymentFormOptions) {
  const t = useI18n();
  const { toast } = useToast();
  const tenantRouter = useTenantRouter();
  const router = useRouter();

  const { tosCheckboxEnabled, onlinePaymentProvider, moyasarPublicTokenKey } =
    useAppStore((state) => state);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    id,
    amountToBePaid = 0,
    submitCart,
    isCartLoading,
    customerId,
  } = useCart();
  const { MADA, CASH, VISA, APPLE_PAY, TAMARA, BANK_TRANSFER } = PaymentMethod;
  const { prevStep } = useCheckoutStore((state) => state);

  const [paymentFormSubmitter, setPaymentFormSubmitter] =
    useState<PaymentFormSubmitter>();

  useEffect(() => {
    let paymentFormSubmitter;

    if (onlinePaymentProvider === "moyasar") {
      paymentFormSubmitter = new MoyasarPaymentFormSubmitter(
        moyasarPublicTokenKey!,
        id!,
      );
    } else if (onlinePaymentProvider === "myfatoorah") {
      paymentFormSubmitter = new MyFatoorahPaymentFormSubmitter(
        customerId!,
        id!,
        tenantRouter,
      );
    } else {
      throw new ApplicationError("Invalid payment provider");
    }

    setPaymentFormSubmitter(paymentFormSubmitter);
    paymentFormSubmitter.Init();
  }, [customerId, id, moyasarPublicTokenKey, onlinePaymentProvider]);

  const isCustomCardFields = onlinePaymentProvider === "moyasar";

  const formSchema = z.object({
    bookingNotes: z.string().optional(),
    ...(isCustomCardFields
      ? {
          number: z
            .string({ required_error: t("validation.required") })
            .refine(isCreditCard, t("validation.cardNumber")),
          name: z
            .string({ required_error: t("validation.required") })
            .regex(/\S+\s+\S+/, t("validation.nameOnCard")),
          month: z
            .string({ required_error: t("validation.required") })
            .min(1, t("validation.required")),
          year: z
            .string({ required_error: t("validation.required") })
            .min(1, t("validation.required")),
          cvc: z
            .string({ required_error: t("validation.required") })
            .min(2, t("validation.required"))
            .max(50),
        }
      : {}),
    ...(paymentMethod === BANK_TRANSFER
      ? {
          bankReceipt: z.array(z.instanceof(File), {
            required_error: t("validation.uplaodBankReceipt"),
          }),
          transferorName: z
            .string()
            .min(1, { message: t("validation.required") }),
        }
      : {}),
    terms: tosCheckboxEnabled
      ? z
          .boolean({
            required_error: t("checkout.termsAndConditionsError"),
          })
          .refine(
            (val) => val === true,
            () => ({ message: t("checkout.termsAndConditionsError") }),
          )
      : z.boolean().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const submitCod = async () => {
    setIsSubmitting(true);
    const isValid = await form.trigger("terms");

    if (!isValid) return;

    try {
      await validateCart(id);
    } catch (error) {
      const isValidError = isValidErrorCode((error as any)?.error?.code);
      const errorTitle = isValidError ? "" : t("error.title");
      toast({
        title: errorTitle,
        description: (error as any)?.error?.message || "",
        duration: 6000,
        variant: "destructive",
      });
      setIsSubmitting(false);
      return;
    }

    submitCart(tenantRouter(callbackUrl), PaymentMethod.CASH);
  };

  const { onUpload, uploadedFiles, progresses, isUploading } = useUploadFile({
    uploadFn: async (formData) =>
      setBankTransferInfo(
        id!,
        (form.getValues() as any).transferorName,
        formData,
      ),
    onUploadProgress: ({ file, progress }) => {
      console.log(`${file.name}: ${progress}%`);
    },
    onError: (error) => {
      console.log("error", error);
      setIsSubmitting(false);
      toast({
        title: (error as any)?.error?.code,
        duration: 2000,
        variant: "destructive",
      });
    },
  });

  const submitBankTransfer = async () => {
    setIsSubmitting(true);
    const isValid = await form.trigger([
      "terms",
      "bankReceipt",
      "transferorName",
    ]);

    console.log("submitBankTransfer", isValid);

    if (!isValid) {
      setIsSubmitting(false);
      return;
    }

    const formData = form.getValues();
    try {
      await onUpload((formData as any).bankReceipt);
      await validateCart(id);

      submitCart(tenantRouter(callbackUrl), PaymentMethod.BANK_TRANSFER);
    } catch (error) {
      const isValidError = isValidErrorCode((error as any)?.error?.code);
      const errorTitle = isValidError ? "" : t("error.title");
      toast({
        title: errorTitle,
        description: (error as any)?.error?.message || "",
        duration: 6000,
        variant: "destructive",
      });
      setIsSubmitting(false);
      return;
    }
  };

  const submitTamara = async () => {
    if (!id) return;

    setIsSubmitting(true);
    try {
      await validateCart(id);
    } catch (error) {
      const isValidError = isValidErrorCode((error as any)?.error?.code);
      const errorTitle = isValidError ? "" : t("error.title");
      toast({
        title: errorTitle,
        description: (error as any)?.error?.message || "",
        duration: 6000,
        variant: "destructive",
      });
      return;
    }
    try {
      const session = await createTamaraSession({
        reference: id,
        cancel_url: getOrigin() + tenantRouter(callbackUrl),
        failure_url: getOrigin() + tenantRouter(callbackUrl),
        success_url: getOrigin() + tenantRouter(callbackUrl),
      });
      router.push(session.checkout_url);
    } catch (error) {
      const isValidError = isValidErrorCode((error as any)?.error?.code);
      setIsSubmitting(false);
      const errorTitle = isValidError ? "" : t("error.title");
      toast({
        title: errorTitle,
        description: (error as any)?.error?.message || "",
        duration: 6000,
        variant: "destructive",
      });
    }
  };

  const creditCardSubmit = async (data: z.infer<typeof formSchema>) => {
    if (!paymentFormSubmitter) return;
    try {
      setIsSubmitting(true);
      await validateCart(id);

      let redirectUrl = await paymentFormSubmitter.Submit(
        data,
        getOrigin() + tenantRouter(callbackUrl),
      );
      router.push(redirectUrl);
      useCustomerStore.getState().reset();
    } catch (error) {
      const isValidError = isValidErrorCode((error as any)?.error?.code);
      const errorTitle = isValidError ? "" : t("error.title");
      toast({
        title: errorTitle,
        description: (error as any)?.error?.message || "",
        duration: 6000,
        variant: "destructive",
      });
    }
    setIsSubmitting(false);
  };

  const validateTos = () => {
    return form.trigger("terms");
  };

  const submitHandler = async () => {
    const isValid = await validateTos();

    console.log("submitHandler", isValid);
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }

    emit({
      event: EventType.InitiateCheckout,
      payment_method: paymentMethod,
    });

    if (paymentMethod === MADA || paymentMethod === VISA) {
      return form.handleSubmit(creditCardSubmit);
    }

    if (paymentMethod === CASH || enabledPaymentMethods?.length === 0) {
      return submitCod();
    }

    if (paymentMethod === TAMARA) {
      return submitTamara();
    }

    if (paymentMethod === BANK_TRANSFER) {
      return submitBankTransfer();
    }
  };

  useEffect(() => {
    if (!isCartLoading) {
      setIsSubmitting(false);
    }
  }, [isCartLoading]);

  useEffect(() => {
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  // show button if any payment method is selected or no payment methods are enabled
  const showSubmitButton = paymentMethod || enabledPaymentMethods?.length === 0;

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(creditCardSubmit)}
          className="mt-4 grid gap-y-4 py-4 pb-24 lg:pb-4"
        >
          {paymentFormSubmitter &&
            (paymentMethod === MADA || paymentMethod === VISA) && (
              <paymentFormSubmitter.Element key={id} />
            )}

          {tosCheckboxEnabled && <TermsAndConditions />}

          {paymentMethod == PaymentMethod.BANK_TRANSFER && (
            <BankTransfer
              form={form}
              uploadedFiles={uploadedFiles}
              progresses={progresses}
              isUploading={isUploading}
            />
          )}

          <div className="hidden items-center gap-x-2 lg:flex">
            <div className="w-1/2">
              {paymentMethod === APPLE_PAY ? (
                <ApplePay
                  amount={amountToBePaid?.toString()}
                  callbackUrl={getOrigin() + tenantRouter(callbackUrl)}
                  validateTos={validateTos}
                  CustomerId={customerId!}
                />
              ) : showSubmitButton ? (
                <Button
                  onClick={submitHandler}
                  disabled={isSubmitting || isCartLoading}
                  className="w-full"
                >
                  {PaymentMethod.CASH === paymentMethod
                    ? codLabel
                    : t("cart.completeButton")}
                  {Boolean(isSubmitting || isCartLoading) && (
                    <Loader className="mx-2" />
                  )}
                </Button>
              ) : null}
            </div>

            <div className="w-1/2">
              <Button
                type="button"
                variant={"outline"}
                onClick={prevStep}
                className="w-full"
              >
                {t("back")}
              </Button>
            </div>
          </div>

          <div className="absolute bottom-0 start-0 flex w-full flex-col justify-center gap-y-8 p-4 lg:hidden">
            <MobileSummary enabledPaymentMethods={enabledPaymentMethods} />
            <div className="flex w-full items-center gap-x-2">
              <div className="w-1/2">
                {paymentMethod === APPLE_PAY ? (
                  <ApplePay
                    amount={amountToBePaid?.toString()}
                    callbackUrl={getOrigin() + tenantRouter(callbackUrl)}
                    validateTos={validateTos}
                    CustomerId={customerId!}
                  />
                ) : showSubmitButton ? (
                  <Button
                    onClick={submitHandler}
                    disabled={isSubmitting || isCartLoading}
                    className="w-full"
                  >
                    {PaymentMethod.CASH === paymentMethod
                      ? codLabel
                      : t("cart.completeButton")}
                    {Boolean(isSubmitting || isCartLoading) && (
                      <Loader className="mx-2" />
                    )}
                  </Button>
                ) : null}
              </div>

              <div className="w-1/2">
                <Button
                  type="button"
                  variant={"outline"}
                  onClick={prevStep}
                  className="w-full"
                >
                  {t("back")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </>
  );
}
