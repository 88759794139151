export enum PaymentError {
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  DECLINED = "DECLINED",
  BLOCKED = "BLOCKED",
  "Payment:Error:TimeFrameExpired" = "Payment:Error:TimeFrameExpired",
  UNSPECIFIED_FAILURE = "UNSPECIFIED_FAILURE",
  EXPIRED_CARD = "EXPIRED_CARD",
  TIMED_OUT = "TIMED_OUT",
  REFERRED = "REFERRED",
  "3-D Secure transaction attempt failed (DECLINED)" = "3-D Secure transaction attempt failed (DECLINED)",
  "Payment:Error:Unknown" = "Payment:Error:Unknown",
}

export type PaymentSession = {
  status: string;
  errorMessageCode?: string;
  redirectUrl: string;
  failureReason: string;
};
