"use client";

import { useRef } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { verifyOtpForCart } from "@repo/libs/api";
import { useI18n } from "@repo/libs/providers/locales/client";
import { CheckoutStep } from "@libs/types/checkout";
import {
  convertToEnglishNumbers,
  REGEXP_ARABIC_AND_ENGLISH_DIGITS,
} from "@libs/utils";

import { Button } from "@repo/ui/components/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@repo/ui/components/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@repo/ui/components/input-otp";
import { useToast } from "@repo/ui/components/use-toast";

import { useCustomerStore } from "@repo/website-toolkit/src/stores/useCustomerStore";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";
import { useCartStore } from "@website-toolkit/stores/cart/useCartStore";
import { useCheckoutStore } from "@website-toolkit/stores/cart/useCheckoutStore";

export default function VerifyOtp({
  shouldBeAutoFocused = false,
  autoNext = () => true,
}: {
  shouldBeAutoFocused: boolean;
  autoNext?: () => boolean;
}) {
  const t = useI18n();
  const { toast } = useToast();
  const lock = useRef(false);

  const formSchema = z.object({
    otpCode: z
      .string({ required_error: t("validation.required") })
      .min(4, t("validation.otpCode"))
      .max(4, t("validation.otpCode"))
      .transform((val) => convertToEnglishNumbers(val))
      .pipe(z.string().regex(/^\d{4}$/, t("validation.otpCode"))),
  });

  const { fetchCart } = useCart();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      otpCode: "",
    },
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    if (lock.current) {
      return;
    }
    lock.current = true;
    try {
      const token = useCustomerStore.getState().otpToken;
      const cartData = await verifyOtpForCart({
        cartId: useCartStore.getState().id!,
        code: data.otpCode,
        token,
      });

      fetchCart();

      useCustomerStore.setState(() => ({
        mobileNumber: cartData.customer?.mobileNumber,
        name: cartData.customer?.name,
        otpVerified: true,
      }));

      if (autoNext()) {
        useCheckoutStore.getState().nextStep();
      } else {
        useCheckoutStore.getState().setStep(CheckoutStep.Summary);
      }
    } catch (err) {
      console.error(err);
      const error = (err as any).error;
      toast({
        title: error?.message,
        description: error?.details,
        variant: "destructive",
      });
    }
    lock.current = false;
  }

  function onComplete(value: string) {
    form.handleSubmit(onSubmit)();
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="grid gap-y-4 p-4 md:pt-0"
      >
        <FormField
          control={form.control}
          defaultValue=""
          name="otpCode"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="grid" dir="ltr">
                  <InputOTP
                    maxLength={4}
                    pattern={REGEXP_ARABIC_AND_ENGLISH_DIGITS}
                    onComplete={onComplete}
                    autoFocus={shouldBeAutoFocused}
                    {...field}
                  >
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                    </InputOTPGroup>
                  </InputOTP>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full"
          type="submit"
          loading={form.formState.isSubmitting}
        >
          {t("continue")}
        </Button>
      </form>
    </Form>
  );
}
