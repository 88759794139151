"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useState } from "react";
import { emit } from "@libs/integrations/meta/events";
import { EventType } from "@libs/types/integrations/meta/event";


type Props = {
  pixelId: string;
}

const MetaPixel = ({ pixelId }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    if (!loaded) return;

    emit(EventType.PageView)
  }, [pathname, loaded]);

  return (
    <div>
      <Script
        id="fb-pixel"
        src="/scripts/meta/pixel.js"
        strategy="afterInteractive"
        onLoad={() => setLoaded(true)}
        data-pixel-id={pixelId}
      />
    </div>
  );
};

export default MetaPixel;