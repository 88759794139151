"use client";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import Script from "next/script";
import React, { useEffect, useState } from "react";

type Props = {
  publicKey: string;
};

const TamaraScript = ({ publicKey }: Props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (window) {
      (window as any)['tamaraWidgetConfig'] = {
        country: 'SA',
        publicKey: publicKey, // The public key is provided by Tamara
        css: `
              :host {
                --font-size-base: 14px !important;
              }
              .tamara-product-widget {
                  @all: unset !important;
              }
              .tamara-summary-widget__amount {
                  font-weight: 600 !important;
              }
              .tamara-summary-widget__container {
                  font-family: inherit !important;
                  justify-content: space-between !important;
              }
              @media (min-width: 768px) {
                .tamara-inline-badge.tamara-badge {
                  padding: 4px !important;
                }
              }
              .tamara-inline-badge.tamara-badge {
                padding: 2px 0px;
              }
            `, // Optional field, It should be a string of CSS that you want to customize
        style: { // Optional to define CSS variable
          fontSize: '10px',
          badgeRatio: 1, // The radio of logo, we can make it big or small by changing the radio.
        }
      };
      (window as any).TamaraWidgetV2?.refresh();
    }
  }, [loaded]);

  return (
    <div>
      <Script
        src={process.env.NEXT_PUBLIC_TAMARA_WIDGET_URL}
        strategy="afterInteractive"
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

export default TamaraScript;
