export enum PlatformDomainErrorCodes {
  // Reservation
  SlotIsNotAvailable = "SP:SlotIsNotAvailable",
  ProviderIsNotAvailable = "SP:ProviderIsNotAvailable",
  MinimumDaysRequirementNotMet = "SP:MinimumDaysRequirementNotMet",
  MaximumDaysExceeded = "SP:MaximumDaysExceeded",
  NextReservationPeriodNotMet = "SP:NextReservationPeriodNotMet",

  // Otp
  OtpCodeMismatch = "SP:OtpCodeMismatch",
  OtpCodeIsExpired = "SP:OtpCodeIsExpired",
  MaxRetriesReached = "SP:MaxRetriesReached",

  // CustomField
  ValueIsRequired = "SP:CustomFieldValueIsRequired",
  ValueMustBeNumber = "SP:ValueMustBeNumber",
  ValueMustBeDate = "SP:ValueMustBeDate",
  OptionIsNotValid = "SP:OptionIsNotValid",

  // CouponUsage
  CouponUsageAlreadyExists = "SP:CouponUsageAlreadyExists",
  CouponIsNotActive = "SP:CouponIsNotActive",
  CouponExpired = "SP:CouponExpired",
  CustomerExceededCouponUsage = "SP:CustomerExceededCouponUsage",
  CouponNotFound = "SP:CouponNotFound",
  CouponMinimumCartAmountNotMet = "SP:CouponMinimumCartAmountNotMet",
  CouponReachedMaximumUsage = "SP:CouponReachedMaximumUsage",
  CouponNotApplicableException = "SP:CouponNotApplicable",
  CouponAlreadyExists = "SP:CouponAlreadyExists",
  CustomerNotApplicableException = "SP:CustomerNotApplicableForCoupon",

  // Schedule
  ScheduleLinkedWithProviderException = "SP:ScheduleLinkedWithProviderException",

  // Provider
  ProviderLinkedWithProductException = "SP:ProviderLinkedWithProductException",
}

export function isCouponError(code: string): boolean {
  return Object.values(PlatformDomainErrorCodes)
    .filter((err) => err.includes("Coupon"))
    .includes(code as PlatformDomainErrorCodes);
}

export function isValidErrorCode(code?: string): boolean {
  if (!code) return false;
  return Object.values(PlatformDomainErrorCodes).includes(
    code as PlatformDomainErrorCodes,
  );
}
