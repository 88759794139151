import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";

import { useI18n } from "@repo/libs/providers/locales/client";
import { getCustomerCustomFields } from "@libs/api/customer";
import { CustomField } from "@libs/types/customFields";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/form";
import { Input } from "@repo/ui/components/input";
import CustomFieldsGroup from "@ui/custom-fields-group";

import { useCustomerStore } from "@repo/website-toolkit/src/stores/useCustomerStore";
import { useAppStore } from "@website-toolkit/components/Providers/app-store-provider";

export default function CustomerInformationFields() {
  const t = useI18n();
  const { control } = useFormContext();
  const { data: customFields } = useQuery({
    queryKey: ["customerCustomFields"],
    queryFn: async () => (await getCustomerCustomFields()) as CustomField[],
  });

  const { separateFirstAndLastName } = useAppStore((state) => state);
  const splitName = useCustomerStore.getState().name?.split(" ");
  const firstName = splitName?.length > 0 ? splitName[0] : undefined;
  const lastName =
    splitName?.length > 1 ? splitName.slice(1).join(" ") : undefined;
  return (
    <>
      <div className="grid gap-4 lg:grid-cols-2">
        {separateFirstAndLastName ? (
          <>
            <FormField
              control={control}
              defaultValue={firstName || ""}
              name="customerFirstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>
                    {t("checkout.customerFirstName")}
                  </FormLabel>
                  <FormControl>
                    <div className="relative grid">
                      <Input
                        placeholder={t("checkout.customerFirstName")}
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              defaultValue={lastName || ""}
              name="customerLastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>
                    {t("checkout.customerLastName")}
                  </FormLabel>
                  <FormControl>
                    <div className="relative grid">
                      <Input
                        placeholder={t("checkout.customerLastName")}
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        ) : (
          <FormField
            control={control}
            defaultValue={useCustomerStore.getState().name || ""}
            name="customerName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t("checkout.customerName")}</FormLabel>
                <FormControl>
                  <div className="relative grid">
                    <Input {...field} />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <FormField
          control={control}
          defaultValue={useCustomerStore.getState().mobileNumber || ""}
          name="customerMobileNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>
                {t("checkout.customerMobileNumber")}
              </FormLabel>
              <FormControl>
                <div className="grid">
                  <Input
                    type="tel"
                    disabled={!!useCustomerStore.getState().mobileNumber}
                    placeholder={t("checkout.customerMobileNumberPlaceholder")}
                    {...field}
                  />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      {customFields && (
        <CustomFieldsGroup control={control} customFields={customFields} />
      )}
    </>
  );
}
