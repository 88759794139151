"use client";

import { Suspense } from "react";

import Link from "next/link";

import { X } from "lucide-react";

import { useCurrentLocale, useI18n } from "@libs/providers/locales/client";
import { useTenantRouter } from "@libs/providers/tenant/client";
import { CheckoutStep } from "@libs/types/checkout";
import { cn } from "@libs/utils";

import Loader from "@repo/ui/components/loader";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@repo/ui/components/sheet";
import { Button } from "@ui/button";

import { useCheckoutStore } from "@repo/website-toolkit/src/stores/cart/useCheckoutStore";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import CheckoutSheetContent from "./CheckoutSheetContent";
import ProgressBar from "./ProgressBar";

export default function CheckoutSheet() {
  const dir = useCurrentLocale() === "ar" ? "rtl" : "ltr";
  const { isSheetOpen, closeSheet, cartItems = [] } = useCart();

  const t = useI18n();
  const { disableMultiCart } = useAppStore((state) => state);
  const { step, nextStep, prevStep } = useCheckoutStore((state) => state);

  const router = useTenantRouter();

  return (
    <Sheet open={isSheetOpen} onOpenChange={(s) => !s && closeSheet()}>
      <SheetContent
        dir={dir}
        side={"bottom"}
        onOpenAutoFocus={(e) => e.preventDefault()}
        hideCloseIcon
        className="flex h-[95svh] flex-col gap-0 rounded-t-xl border-0 p-0"
      >
        <SheetHeader className="border-theme-background-700 border-b p-3 lg:px-6">
          <div className="mx-auto flex w-full items-center justify-between">
            <SheetTitle className="sr-only"></SheetTitle>
            <ProgressBar className="!mt-8" currentStep={step} />
            <Button variant="ghost" size={"icon"} onClick={closeSheet}>
              <X className="size-5" />
            </Button>
          </div>
        </SheetHeader>

        <Suspense fallback={<Loader />}>
          <CheckoutSheetContent />
        </Suspense>

        {step === CheckoutStep.Summary && (
          <SheetFooter
            className={cn(
              "items-center justify-center p-3 lg:hidden",
              cartItems.length === 0 && "mt-0",
            )}
          >
            {cartItems.length === 0 ? (
              <Link href={router(`/products`)} className="h-full w-full">
                <Button onClick={closeSheet} className="w-full">
                  {t("cart.addProduct")}
                </Button>
              </Link>
            ) : (
              <div
                className={cn(
                  "flex w-full items-center justify-center gap-x-2",
                )}
              >
                <div className="w-1/2">
                  <Button onClick={nextStep} className="w-full">
                    {t("continue")}
                  </Button>
                </div>

                <div className="w-1/2">
                  <Link href={router(`/products`)} className="h-full w-full">
                    <Button
                      variant={"outline"}
                      onClick={!disableMultiCart ? closeSheet : prevStep}
                      className="w-full"
                    >
                      {!disableMultiCart ? t("cart.addAnother") : t("back")}
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </SheetFooter>
        )}
      </SheetContent>
    </Sheet>
  );
}
