"use client";

import * as React from "react";

import { OTPInput, OTPInputContext } from "input-otp";
import { Dot } from "lucide-react";

import { cn } from "../lib/utils";

const CardThemeContext = React.createContext<"customer" | "rekaz">("customer");

const slotThemeStyles = {
  customer: {
    base: "border-theme-background-700 text-theme-text",
    active: "ring-theme-primary ring-offset-theme-background",
    caret: "bg-theme-text",
  },
  rekaz: {
    base: "border-neutral-200 text-neutral-950",
    active: "ring-neutral-950 ring-offset-white",
    caret: "bg-neutral-950",
  },
};

const InputOTP = React.forwardRef<
  React.ElementRef<typeof OTPInput>,
  React.ComponentPropsWithoutRef<typeof OTPInput> & {
    theme?: "customer" | "rekaz";
  }
>(({ className, containerClassName, theme = "customer", ...props }, ref) => (
  <CardThemeContext.Provider value={theme}>
    <OTPInput
      ref={ref}
      containerClassName={cn(
        "flex items-center gap-2 has-[:disabled]:opacity-50 mx-auto",
        containerClassName,
      )}
      className={cn("disabled:cursor-not-allowed", className)}
      {...props}
    />
  </CardThemeContext.Provider>
));
InputOTP.displayName = "InputOTP";

const InputOTPGroup = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex items-center", className)} {...props} />
));
InputOTPGroup.displayName = "InputOTPGroup";

const InputOTPSlot = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div"> & { index: number }
>(({ index, className, ...props }, ref) => {
  const theme = React.useContext(CardThemeContext);
  const inputOTPContext = React.useContext(OTPInputContext);
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

  return (
    <div
      ref={ref}
      className={cn(
        "relative flex h-12 w-12 items-center justify-center border-y-2 border-r-2 text-sm transition-all first:rounded-l-md first:border-l-2 last:rounded-r-md",
        slotThemeStyles[theme].base,
        isActive && `z-10 ring-2 ${slotThemeStyles[theme].active}`,
        className,
      )}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div
            className={cn(
              "animate-caret-blink h-4 w-px duration-1000",
              slotThemeStyles[theme].caret,
            )}
          />
        </div>
      )}
    </div>
  );
});
InputOTPSlot.displayName = "InputOTPSlot";

const InputOTPSeparator = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ ...props }, ref) => (
  <div ref={ref} role="separator" {...props}>
    <Dot />
  </div>
));
InputOTPSeparator.displayName = "InputOTPSeparator";

export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };
