import { useMemo, useState } from "react";

import { useCurrentLocale, useI18n } from "@repo/libs/providers/locales/client";
import { PaymentMethod } from "@repo/libs/types/app";
import { ProductType } from "@repo/libs/types/products";

import {
  ApplePay,
  BankTransfer,
  CashOnDelivery,
  Mada,
  MasterCard,
  TamaraAr,
  TamaraEn,
  Visa,
} from "@repo/ui/components/icons";
import { Label } from "@repo/ui/components/label";
import { RadioGroup, RadioGroupItem } from "@repo/ui/components/radio-group";

import { useAppStore } from "@website-toolkit/components/Providers/app-store-provider";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";
import { useCartStore } from "@website-toolkit/stores/cart/useCartStore";

import PaymentForm from "./Payment/PaymentForm";

interface PaymentOptionProps {
  icon: React.ReactNode;
  label: string;
  value: PaymentMethod;
  disabled?: boolean;
}

export default function PaymentMethods() {
  const t = useI18n();
  const locale = useCurrentLocale();
  const cartId = useCartStore((state) => state.id);

  const { cartItems, totalAmount, depositAmount, isCartLoading } = useCart();
  const { enabledPaymentMethods, disableCODForDeposit } = useAppStore(
    (state) => state,
  );

  const toBePaid = totalAmount ?? 0;

  const { codLabel } = useMemo(() => {
    const isReservation = cartItems?.every(
      (item) => item?.product?.typeString === ProductType.RESERVATION,
    );

    const isSubscription = cartItems?.every(
      (item) => item?.product?.typeString === ProductType.SUBSCRIPTION,
    );

    let label = t("cart.cashOnDelivery");
    if (isReservation) {
      label = t("booking.checkout.payLater");
      if (toBePaid === 0 || !enabledPaymentMethods?.length) {
        label = t("booking.checkout.confirmBooking");
      }
    }
    if (toBePaid === 0 && isSubscription) {
      label = t("subscription.checkout.confirmSubscription");
    }

    return { isReservation, isSubscription, codLabel: label };
  }, [cartItems, toBePaid, enabledPaymentMethods]);

  const paymentOptions = useMemo(() => {
    const options: Array<{
      type: PaymentMethod;
      enabled?: boolean;
      icon: React.ReactNode;
      label: string;
    }> = [
      {
        type: PaymentMethod.VISA,
        enabled:
          enabledPaymentMethods?.includes(PaymentMethod.VISA) && toBePaid > 0,
        icon: (
          <div className="flex">
            <Visa className="h-10 w-10" />
            <MasterCard className="h-10 w-10" />
          </div>
        ),
        label: t("paymentMethod.creditCard"),
      },
      {
        type: PaymentMethod.MADA,
        enabled:
          enabledPaymentMethods?.includes(PaymentMethod.MADA) && toBePaid > 0,
        icon: <Mada className="h-10 w-12" />,
        label: t("paymentMethod.mada"),
      },
      {
        type: PaymentMethod.CASH,
        enabled:
          (enabledPaymentMethods?.includes(PaymentMethod.CASH) ||
            toBePaid === 0) &&
          !(disableCODForDeposit && depositAmount !== 0),
        icon: <CashOnDelivery className="h-10 w-10" />,
        label: codLabel,
      },
      {
        type: PaymentMethod.APPLE_PAY,
        enabled:
          enabledPaymentMethods?.includes(PaymentMethod.APPLE_PAY) &&
          toBePaid > 0,
        icon: <ApplePay className="h-12 w-16" />,
        label: t("paymentMethod.applepay"),
      },
      {
        type: PaymentMethod.TAMARA,
        enabled:
          enabledPaymentMethods?.includes(PaymentMethod.TAMARA) && toBePaid > 0,
        icon:
          locale === "ar" ? (
            <TamaraAr className="h-12 w-16" />
          ) : (
            <TamaraEn className="h-12 w-16" />
          ),
        label: t("paymentMethod.tamara"),
      },
      {
        type: PaymentMethod.BANK_TRANSFER,
        enabled:
          enabledPaymentMethods?.includes(PaymentMethod.BANK_TRANSFER) &&
          toBePaid > 0,
        icon: <BankTransfer className="h-10 w-8 stroke-2" />,
        label: t("paymentMethod.bankTransfer"),
      },
    ];

    return options.filter((option) => option.enabled);
  }, [
    enabledPaymentMethods,
    toBePaid,
    locale,
    codLabel,
    disableCODForDeposit,
    depositAmount,
    t,
  ]);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>(
    () => paymentOptions[0]?.type,
  );

  if (!cartItems?.length) {
    return (
      <div className="mt-24 flex justify-center" role="status">
        <div className="border-theme-primary h-8 w-8 animate-spin rounded-full border-4 border-t-transparent" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <RadioGroup
        className="flex flex-col gap-2"
        disabled={isCartLoading}
        value={paymentMethod}
        onValueChange={(value: PaymentMethod) => setPaymentMethod(value)}
      >
        {paymentOptions.map((option) => (
          <PaymentOption
            key={option.type}
            icon={option.icon}
            label={option.label}
            value={option.type}
            disabled={isCartLoading}
          />
        ))}
      </RadioGroup>

      <PaymentForm
        key="payment-form"
        paymentMethod={paymentMethod}
        enabledPaymentMethods={enabledPaymentMethods}
        callbackUrl={`/checkout/done/${cartId}`}
        codLabel={codLabel}
      />
    </div>
  );
}

const PaymentOption = ({
  icon,
  label,
  value,
  disabled,
}: PaymentOptionProps) => (
  <Label
    htmlFor={value.toLowerCase()}
    className={`border-theme-background-700 flex h-full cursor-pointer items-center justify-between rounded-md border p-4 shadow-sm transition-colors duration-200 
      ${disabled ? "cursor-not-allowed opacity-50" : "hover:bg-theme-background-600"}`}
  >
    <div className="flex items-center">{icon}</div>
    <div className="flex items-center">
      <span className="mx-4 text-center">{label}</span>
      <RadioGroupItem
        value={value}
        id={value.toLowerCase()}
        disabled={disabled}
        aria-label={label}
      />
    </div>
  </Label>
);
