"use client";

import { useState } from "react";

import { useRouter } from "next/navigation";
import Script from "next/script";

import * as Sentry from "@sentry/nextjs";

import { createPaymentSession } from "@repo/libs/api/payment";
import { useI18n } from "@repo/libs/providers/locales/client";
import { useTenantRouter } from "@repo/libs/providers/tenant/client";
import { enumFromStringValue, getHost, getOrigin } from "@repo/libs/utils";
import { validateCart } from "@libs/api/cart";
import { isValidErrorCode } from "@libs/errors/errorsEnum";
import { PaymentError } from "@libs/types/payments";
import {
  ApplePayPaymentAuthorizedEvent,
  ApplePayValidateMerchantEvent,
} from "@libs/types/payments/applepay";

import { Button } from "@repo/ui/components/button";
import { Apple } from "@repo/ui/components/icons";
import { useToast } from "@repo/ui/components/use-toast";
import { ToastAction } from "@ui/toast";

import { useAppStore } from "@repo/website-toolkit/src/components/Providers/app-store-provider";
import { useCustomerStore } from "@repo/website-toolkit/src/stores/useCustomerStore";
import { useCart } from "@website-toolkit/components/Providers/CartProvider";

import { ApplePayProps } from "./ApplePay";

export default function MoyasarApplePay(props: ApplePayProps) {
  const router = useRouter();
  const { toast } = useToast();
  const t = useI18n();
  const tenantRouter = useTenantRouter();
  const { submitCart } = useCart();

  const { moyasarPublicTokenKey } = useAppStore((state) => state);

  const [activeSession, setActiveSession] = useState<
    typeof window.ApplePaySession | null
  >(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { id } = useCart();

  async function onApplePayButtonClicked() {
    if (!window.ApplePaySession || isProcessing) {
      return;
    }

    setIsProcessing(true);

    // Abort any existing session before starting a new one
    if (activeSession && activeSession instanceof window.ApplePaySession) {
      activeSession.abort();
      setActiveSession(null);
    }

    let isValidInputs = await props.validateTos();
    if (!isValidInputs) {
      setIsProcessing(false);
      setActiveSession(null);
      return;
    }

    const applePayPaymentRequest = {
      countryCode: "SR",
      currencyCode: "SAR",
      supportedNetworks: ["mada", "visa", "masterCard"],
      merchantCapabilities: ["supports3DS"],
      total: { label: "*Rekaz", amount: props.amount },
    };

    const session = new window.ApplePaySession(6, applePayPaymentRequest);
    setActiveSession(session);

    const safeAbort = () => {
      if (session && session instanceof window.ApplePaySession) {
        session.abort();
      }
      setActiveSession(null);
      setIsProcessing(false);
    };

    session.onvalidatemerchant = async (
      event: ApplePayValidateMerchantEvent,
    ) => {
      try {
        await validateCart(id);
      } catch (error) {
        safeAbort();
        const isValidError = isValidErrorCode((error as any)?.error?.code);
        const errorTitle = isValidError ? "" : t("error.title");
        toast({
          title: errorTitle,
          description: (error as any)?.error?.message || "",
          duration: 6000,
          variant: "destructive",
        });

        return;
      }

      const body = {
        validation_url: event.validationURL,
        display_name: "Rekaz",
        domain_name: getHost(),
        publishable_api_key: moyasarPublicTokenKey,
      };

      fetch("https://api.moyasar.com/v1/applepay/initiate", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
        .then((merchantSession) => merchantSession.json())
        .then((merchantSessionJson) => {
          session.completeMerchantValidation(merchantSessionJson);
        })
        .catch((err) => {
          console.error("error validating merchant", err);
          Sentry.captureException(err);

          toast({
            title: t("error.title"),
            variant: "destructive",
          });
        });
    };

    session.onpaymentauthorized = async (
      event: ApplePayPaymentAuthorizedEvent,
    ) => {
      if (!id) {
        throw new Error("No order id found");
      }

      const token = event.payment.token;
      var paymentSessionRequest = {
        callbackUrl: props.callbackUrl + "?status=paid",
        token: token,
        tokenType: "applepay",
        description: id,
        reference: id,
      };

      createPaymentSession(paymentSessionRequest)
        .then((paymentSession) => {
          if (paymentSession.status != "Paid") {
            safeAbort();

            if (paymentSession.errorMessageCode) {
              const errorMessage = enumFromStringValue(
                PaymentError,
                paymentSession.failureReason,
              );
              toast({
                title: t(errorMessage || "Payment:Error:Unknown"),
                variant: "destructive",
              });
            }

            return;
          } else {
            session.completePayment({
              status: window.ApplePaySession.STATUS_SUCCESS,
            });

            useCustomerStore.getState().reset();
            router.push(props.callbackUrl + "?status=paid");
            // submitCart(
            //   getOrigin() + tenantRouter(props.callbackUrl) + "?status=paid",
            //   PaymentMethod.APPLE_PAY,
            // );
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log("error creating payment session", err);
        });
    };

    session.oncancel = (event: any) => {
      console.log("Apple Pay session cancelled");
      setActiveSession(null);
      setIsProcessing(false);
    };

    try {
      session.begin();
    } catch (err) {
      console.log("error starting apple pay session", err);
      toast({
        title: (err as any).message,
        variant: "destructive",
        action: (
          <ToastAction altText={t("error.title")} onClick={safeAbort}>
            {t("error.retry")}
          </ToastAction>
        ),
      });
      setIsProcessing(false);
      return;
    }
  }
  return (
    <>
      <Script
        src="https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js"
        strategy="lazyOnload"
      />
      <Button className="w-full" onClick={onApplePayButtonClicked}>
        <Apple className="h-[1.15rem] w-auto text-white" />
      </Button>
    </>
  );
}
