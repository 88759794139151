import { UUID } from "crypto";

export enum OtpPurpose {
  ConfirmCheckout = 0,
  CheckCustomerStatus = 1,
}

export interface CreateOtp {
  mobileNumber: string;
  purpose: OtpPurpose;
}

export interface CreateOtpOutput {
  token: string;
}

export interface VerifyOtp {
  cartId: UUID;
  code: string;
  token: string;
}

export interface VerifyOtpOutput {
  customerName: string;
  customerMobileNumber: string;
}
