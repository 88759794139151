"use client";

import * as React from "react";

import { X } from "lucide-react";

import { cn } from "../lib/utils";

const CardThemeContext = React.createContext<"customer" | "rekaz">("customer");

const overlayThemeStyles = {
  customer: "bg-black/80",
  rekaz: "bg-neutral-950/80",
};

const contentThemeStyles = {
  customer: "bg-theme-background border-theme-background-700",
  rekaz: "bg-white border-neutral-200",
};

const closeButtonThemeStyles = {
  customer:
    "ring-offset-theme-background text-theme-text/65 hover:text-theme-text focus:ring-theme-primary",
  rekaz:
    "ring-offset-white text-neutral-500/65 hover:text-neutral-950 focus:ring-neutral-950",
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeButtonPlacement?: "top-right" | "top-left";
  isInsideCard?: boolean;
  theme?: "customer" | "rekaz";
  children: React.ReactNode;
}

const Modal = ({
  isOpen,
  onClose,
  closeButtonPlacement = "top-left",
  theme = "customer",
  isInsideCard,
  children,
}: ModalProps) => {
  const [shouldRender, setShouldRender] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
    } else {
      const timer = setTimeout(() => setShouldRender(false), 200);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!shouldRender) return null;

  return (
    <CardThemeContext.Provider value={theme}>
      {/* Overlay */}
      <div
        className={cn(
          "fixed inset-0 z-50 transition-opacity duration-200",
          theme === "customer"
            ? overlayThemeStyles.customer
            : overlayThemeStyles.rekaz,
          isInsideCard ? "rounded-t-xl" : "",
          isOpen
            ? "animate-in fade-in-0 opacity-100"
            : "animate-out fade-out-0 opacity-0",
        )}
        onClick={onClose}
      />

      {/* Content */}
      <div
        className={cn(
          "fixed left-[50%] top-[50%] z-50 grid w-full max-w-[22.5rem] translate-x-[-50%] translate-y-[-50%] gap-5 rounded-lg border p-6 shadow-lg transition-all duration-200",
          theme === "customer"
            ? contentThemeStyles.customer
            : contentThemeStyles.rekaz,
          "sm:max-w-lg",
          isOpen
            ? "animate-in fade-in-0 zoom-in-95 slide-in-from-left-1/2 slide-in-from-top-[48%] scale-100 opacity-100"
            : "animate-out fade-out-0 zoom-out-95 slide-out-to-left-1/2 slide-out-to-top-[48%] scale-95 opacity-0",
        )}
      >
        {children}
        <button
          onClick={onClose}
          className={cn(
            "absolute rounded-sm transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none",
            theme === "customer"
              ? closeButtonThemeStyles.customer
              : closeButtonThemeStyles.rekaz,
            closeButtonPlacement === "top-right"
              ? "right-4 top-4"
              : "left-4 top-4",
          )}
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </button>
      </div>
    </CardThemeContext.Provider>
  );
};

const ModalHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn("flex flex-col space-y-1.5 text-center", className)}
    {...props}
  />
);
ModalHeader.displayName = "ModalHeader";

const ModalFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className,
    )}
    {...props}
  />
);
ModalFooter.displayName = "ModalFooter";

const ModalTitle = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) => {
  const theme = React.useContext(CardThemeContext);
  return (
    <h2
      className={cn(
        "text-lg font-semibold leading-none tracking-tight md:text-xl",
        theme === "customer" ? "text-theme-text" : "text-neutral-950",
        className,
      )}
      {...props}
    />
  );
};
ModalTitle.displayName = "ModalTitle";

const ModalDescription = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) => {
  const theme = React.useContext(CardThemeContext);
  return (
    <p
      className={cn(
        "text-center",
        theme === "customer" ? "text-theme-text/75" : "text-neutral-500",
        className,
      )}
      {...props}
    />
  );
};
ModalDescription.displayName = "ModalDescription";

export { Modal, ModalHeader, ModalFooter, ModalTitle, ModalDescription };
