import { useEffect, useState } from "react";

import Image from "next/image";

import { useQuery } from "@tanstack/react-query";
import { Copy } from "lucide-react";
import { UseFormReturn } from "react-hook-form";

import { getBankAccounts } from "@libs/api/payment";
import { useI18n } from "@libs/providers/locales/client";
import { UploadedFile } from "@libs/types/file-upload";
import { BankAccount } from "@libs/types/payments/bankTransfer";
import { copyToClipboard } from "@libs/utils";

import { Card, CardContent, CardFooter } from "@ui/card";
import { FileUploader, UploadedFilesCard } from "@ui/file-uploader";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@ui/form";
import { Input } from "@ui/input";
import { Label } from "@ui/label";
import { RadioGroup, RadioGroupItem } from "@ui/radio-group";
import { useToast } from "@ui/use-toast";

import { useCart } from "@website-toolkit/components/Providers/CartProvider";

type BakTransferProps = {
  form: UseFormReturn<any>;
  uploadedFiles: UploadedFile<unknown>[];
  progresses: Record<string, number>;
  isUploading: boolean;
};

export default function BankTransfer({
  form,
  uploadedFiles,
  progresses,
  isUploading,
}: BakTransferProps) {
  const t = useI18n();
  const { toast } = useToast();

  const { data: bankAccounts } = useQuery<BankAccount[]>({
    queryKey: ["bank-accounts"],
    queryFn: getBankAccounts,
  });

  const [selectedBank, setSelectedBank] = useState<BankAccount | undefined>(
    bankAccounts && bankAccounts[0],
  );

  useEffect(() => {
    if (bankAccounts && bankAccounts.length > 0 && !selectedBank) {
      setSelectedBank(bankAccounts[0]);
    }
  }, [bankAccounts, selectedBank]);

  const { amountToBePaid = 0 } = useCart();

  if (!bankAccounts || bankAccounts.length === 0) return null;

  const copy = (text: string) =>
    copyToClipboard(
      text,
      () => {
        toast({
          title: t("text.copied"),
          duration: 2000,
          variant: "success",
          style: {
            width: "10rem",
          },
        });
      },
      (err) => {
        toast({
          title: t("text.copyFailed"),
          duration: 2000,
          variant: "destructive",
          style: {
            width: "10rem",
          },
        });
        console.error(err);
      },
    );

  return (
    <div className="flex flex-col gap-y-6">
      {bankAccounts && bankAccounts?.length > 1 && (
        <RadioGroup
          value={selectedBank?.accountNumber}
          onValueChange={(value: string) => {
            const bank = bankAccounts.find((b) => b.accountNumber == value);
            setSelectedBank(bank);
          }}
        >
          <div className="flex items-center justify-end space-x-2">
            {bankAccounts?.map((account) => (
              <Label
                key={account.accountNumber}
                htmlFor={account.accountNumber}
                className="border-theme-background-700 flex h-full cursor-pointer items-center justify-between rounded-md border p-4 shadow-sm transition-colors duration-200"
              >
                <div className="flex items-center">
                  <Image
                    src={`https://cdn.rekaz.io/assets/logos/${account.bank}.webp`}
                    width="150"
                    height="150"
                    alt={account.bank.toString()}
                  />
                </div>
                <div className="flex items-center">
                  <RadioGroupItem
                    value={account.accountNumber}
                    id={account.accountNumber}
                  />
                </div>
              </Label>
            ))}
          </div>
        </RadioGroup>
      )}

      {selectedBank != null && (
        <Card className="mb-4">
          <CardContent>
            <div className="mt-4 flex flex-col gap-2 gap-y-4 text-sm">
              <div className="flex w-full flex-col md:flex-row">
                <div className="mb-1 font-bold md:mb-0 md:w-1/2">
                  {t("bankTransfer.bankName")}
                </div>
                {/* @ts-ignore */}
                <div className="md:w-1/2">{t(`bank.${selectedBank.bank}`)}</div>
              </div>
              <div className="flex w-full flex-col md:flex-row">
                <div className="mb-1 font-bold md:mb-0 md:w-1/2">
                  {t("bankTransfer.holderName")}
                </div>
                <div className="md:w-1/2">{selectedBank.holderName}</div>
              </div>
              <div
                className="flex w-full flex-col md:flex-row"
                onClick={() => copy(selectedBank.iban)}
              >
                <div className="mb-1 font-bold md:mb-0 md:w-1/2">
                  {t("bankTransfer.iban")}
                </div>
                <div className="hover:text-theme-primary focus:text-theme-primary active:text-theme-primary flex items-center gap-x-2 md:w-1/2">
                  {selectedBank.iban}
                  <Copy />
                </div>
              </div>
              <div
                className="flex w-full flex-col md:flex-row"
                onClick={() => copy(selectedBank.accountNumber)}
              >
                <div className="mb-1 font-bold md:mb-0 md:w-1/2">
                  {t("bankTransfer.accountNumber")}
                </div>
                <div className="hover:text-theme-primary flex items-center gap-x-2 md:w-1/2">
                  {selectedBank.accountNumber}
                  <Copy />
                </div>
              </div>
            </div>
          </CardContent>
          <hr />
          <CardFooter className="mt-4">
            <span className="me-4">{t("cart.amountToBePaid")}:</span>
            <span>
              {amountToBePaid} {t("sar")}
            </span>
          </CardFooter>
        </Card>
      )}

      <FormField
        control={form.control}
        name="transferorName"
        defaultValue=""
        render={({ field }) => (
          <FormItem className="">
            <FormLabel className="text-md ps-2">
              {t("checkout.transferorName")}
            </FormLabel>
            <FormControl>
              <div className="relative grid">
                <Input id="transferorName" {...field} />
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="bankReceipt"
        render={({ field }) => (
          <div className="space-y-6">
            <FormItem className="w-full">
              <FormLabel className="text-md ps-2">
                {t("bankTransfer.uploadReceipt")}
              </FormLabel>
              <FormControl>
                <FileUploader
                  value={field.value}
                  onValueChange={field.onChange}
                  maxFileCount={1}
                  maxSize={1 * 1024 * 1024}
                  className="my-2"
                  accept={{
                    "image/*": [],
                    "application/pdf": [],
                  }}
                  progresses={progresses}
                  // onUpload={(file) => console.log("uploading", file)}
                  disabled={isUploading}
                >
                  <p className="text-gray-600">
                    {t("bankTransfer.upload.receipt")}
                  </p>
                </FileUploader>
              </FormControl>
              <FormMessage />
            </FormItem>
            {uploadedFiles.length > 0 ? (
              <UploadedFilesCard uploadedFiles={uploadedFiles} />
            ) : null}
          </div>
        )}
      />
    </div>
  );
}
